export const defaultFontFamily = 'Pretendard Variable';

export const colorPalette = {
  svgblack: '#1C1B1F',
  datepickerblack: 'rgba(0, 0, 0, 0.87)',
  background: '#ECECF5',
  dataVisualizationGray: '#7084A3',
  gray50: '#FAFAFA',
  gray100: '#F5F5F5',
  gray200: '#EEE',
  gray300: '#E0E0E0',
  gray500: '#9E9E9E',
  gray600: '#9E9E9E',
  gray700: '#616161',
  gray800: '#424242',
  gray900: '#222222',
  green50: '#E5FCE3',
  kice_green: '#5DA928',
  green100: '#A5F4A0',
  green500: '#00C400',
  green600: '#01A900',
  green700: '#018600',
  teal500: '#43BED0',
  purple50: '#FBF5FF',
  purple500: '#9D50E5',
  blue50: '#EEF7FF',
  blue100: '#CBE8FF',
  blue200: '#A7D9FF',
  blue500: '#067DFD',
  blue600: '#0062E5',
  blue700: '#0048C8',
  orange50: '#FFF6F2',
  orange100: '#FFF6F2',
  orange400: '#FF7638',
  orange500: '#F9560E',
  orange700: '#B43200',
  yellow50: '#FFFCE5',
  yellow100: '#FDF8C8',
  yellow400: '#FFCC1F',
  yellow500: '#EAAD06',
  yellow600: '#CF8B00',
  yellow700: '#AC6600',
  indigo50: '#F5F5FF',
  indigo300: '#7986CB',
  indigo500: '#706FF7',
  indigo900: '#2D2587',
  red50: '#FFF5F5',
  red100: '#FFDADB',
  red500: '#EF2B2A',
  red600: '#DA120D',
  red700: '#BF0A03',
  pink500: '#E413C3',
  white: '#FFF',
  black: '#000',
  none: 'none',
  transparent: 'transparent'
} as const;

export type ColorPalette = typeof colorPalette;

export const headerHeight = 70;
export const sidebarWidth = 258;