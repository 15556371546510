import {Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, Typography} from "@mui/material";
import {colorPalette, defaultFontFamily} from "../const";
import React from "react";

interface DialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  content: string;
}

export default (props: DialogProps) => {
  const {open, title, content, setOpen} = props;
  return (
    <>
      <Dialog
        open={open} sx={{
        m: 0, p: 0
      }}
        PaperProps={{
          sx: {
            borderRadius: 2,
          }
        }}
      >
        <DialogTitle sx={{px: 2, pt: 2, pb: 0}}>
          <Typography sx={{
            color: colorPalette.gray900,
            fontFamily: defaultFontFamily,
            fontSize: 16,
            fontWeight: 600
          }}>
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{px: 2, py: '16px !important'}}>
          <DialogContentText>
            <Typography sx={{
              color: colorPalette.gray700,
              fontFamily: defaultFontFamily,
              fontSize: 16,
              fontWeight: 400
            }}>
              {content}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <Box sx={{
          pb: 2, px: 2, width: '100%',
        }}>
          <Button
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 1,
              color: colorPalette.white,
              fontFamily: defaultFontFamily,
              fontSize: 14,
              fontWeight: 600,
              backgroundColor: colorPalette.kice_green,
              width: '100%',
            }}
            onClick={(e) => {
              setOpen(false);
            }}>
            확인
          </Button>
        </Box>
      </Dialog>
    </>
  )
}