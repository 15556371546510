import {
  Box,
  IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Typography,
} from '@mui/material';
import {Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  applyPagination, parseJwt,
} from '../../util';
import {colorPalette, defaultFontFamily} from "../../const";
import SelectBox from "../../components/SelectBox";
import {searchItems} from "../../consts";
import InputField from "../../components/InputField";
import {ReactComponent as BackButton} from '../../assets/back_btn.svg';
import {ReactComponent as ArrowLeft} from '../../assets/arrow_left.svg';
import {ReactComponent as ArrowRight} from '../../assets/arrow_right.svg';
import {client} from "../../network.config";

interface Props {
  records: any[];
  area: string;
  currentSchool: string;
  inspectLevel: number;
  completeLevel: number;
  processStatus: number;
  setProcessStatus: Dispatch<SetStateAction<number>>;
  period: number;
  setPeriod: Dispatch<SetStateAction<number>>;
  setIsPopupOpen: Dispatch<SetStateAction<boolean>>;
  setPopupTitle: Dispatch<SetStateAction<string>>;
  setPopupContent: Dispatch<SetStateAction<string>>;
  showAsTable: boolean;
}

function DashboardDetail(props: Props) {
  const navigate = useNavigate();
  const {
    records,
    area,
    currentSchool,
    inspectLevel,
    completeLevel,
    processStatus,
    setProcessStatus,
    period,
    setPeriod,
    setIsPopupOpen,
    setPopupTitle,
    setPopupContent,
    showAsTable
  } = props;

  const onTestTypes = ['학교 정보', '요원 정보', '시험 당일 점검', '시험 진행', '시험 후 정리'];
  const [onTestStates, setOnTestStates] = useState<number[]>([]);
  const [filteredData, setFilteredData] = useState([]);
  const [agents, setAgents] = useState([]);
  const location = useLocation();
  const showAgentStatus = Boolean(location.state?.showAgentStatus);
  // true: 미도착 (시험 시작 1시간 전까지 도착 버튼 누르지 않은 학교)
  // false: 미출발 (시험 시작 2시간 전까지 출발 버튼 누르지 않은 학교)
  const isShowingWhoClickedArrivalBtn = Boolean(location.state?.showArrivalStatus);

  const [paginatedRows, setPaginatedRows] = useState(
    applyPagination(filteredData, 0, 99999)
  );

  const [searchItem, setSearchItem] = useState('학교명');
  const [searchQuery, setSearchQuery] = useState('');
  const [currentTestType, setCurrentTestType] = useState(0);

  const textStyle = {
    color: colorPalette.gray900,
    fontFamily: defaultFontFamily,
    fontWeight: 600
  };

  const getAgentInfo = async (inspectLevel: number, area: string, currentSchool: string) => await client
    .get(`/get_agent_info?inspectLevel=${inspectLevel}&schoolLevel=${currentSchool}&area=${area}`)
    .catch((e) => {
      console.error(e);
    });

  // 진행구분 눌렀을때 완료 나오도록 처리
  // @ts-ignore
  const compare = (arr: any, key: string) => {
    return ['진행', '완료'].includes(arr[key])
  };

  const parsedToken = useMemo(() => {
    const token = window.sessionStorage.getItem('kice_token');
    return Boolean(token) ? parseJwt(token as string) : {};
  }, [window.sessionStorage.getItem('kice_token')]);

  // const isLate = (departureTime?: Date | null, arrivalTime?: Date | null, startTime?: Date | null, debug: boolean = false) => {
  //   const isArrivalTimeValid = Boolean(arrivalTime) && !isNaN(arrivalTime!.getTime());
  //   const isDepartureTimeValid = Boolean(departureTime) && !isNaN(departureTime!.getTime());
  //   // startTime은 valid 하다고 가정
  //   // const isStartTimeValid = Boolean(startTime) && !isNaN(startTime!.getTime());
  //   const diffForCheck = isShowingWhoClickedArrivalBtn ? 60 : 120;
  //   // @ts-ignore
  //   const diff = isShowingWhoClickedArrivalBtn ? differenceInMinutes(startTime, arrivalTime) : differenceInMinutes(startTime, departureTime);
  //   // @ts-ignore
  //   const diffNow = differenceInMinutes(startTime, new Date());
  //   if (debug) {
  //     console.log(startTime);
  //     console.log(differenceInMinutes(toZonedTime(startTime!.toISOString(), 'Asia/Seoul'), toZonedTime(arrivalTime!.toISOString(), 'Asia/Seoul')));
  //     console.log(startTime);
  //     console.log(departureTime);
  //     console.log(diff);
  //   }
  //   return ((isShowingWhoClickedArrivalBtn && isArrivalTimeValid) || (!isShowingWhoClickedArrivalBtn && isDepartureTimeValid)) && ((/* diff > 0 &&  */ diff <= diffForCheck)
  //     // @ts-ignore
  //     || (/* diffNow > 0 && */diffNow <= diffForCheck));
  // }

  useEffect(() => {
    getAgentInfo(inspectLevel, area, currentSchool).then((agentInfo: any) => {
      const newAgents = agentInfo.data.data;
      setAgents(newAgents);
      let tRecords =
        Boolean(area) ?
          records.filter((d) => d.gubun === area)[0]?.data ?? []
          : records.flat(1).filter(({gubun}) => gubun === area).map(({data}) => data).sort(({학교명: schoolNameA}, {학교명: schoolNameB}) => schoolNameA.localeCompare(schoolNameB));
      // 지역, 학교명, 학교주소, 학교연락처, 학교선생님연락처
      tRecords = tRecords.map((record: any) => {
        const defaultRecord = {
          job02_pk: record.job02_pk,
          지역: record.지역,
          학교명: record.학교명,
          학교주소: record.학교주소,
          학교연락처: record.학교연락처,
          학교선생님연락처: record.학교선생님연락처,
          현장요원: record.현장요원,
          현장요원_연락처: record.현장요원_연락처
        }
        switch (inspectLevel) {
          case 0:
            return {
              ...defaultRecord,
              사전점검: record.사전점검,
              agent_name_pre: record.agent_name_pre
            }
          case 1:
            return {
              ...defaultRecord,
              최적화: record.최적화,
              agent_name_opt: record.agent_name_opt
            }
          case 2:
            const isSecond = !!+record.is_second;
            const dd1 = !isSecond && Boolean(record.departure_dt) ? new Date(record.departure_dt) : null;
            const ad1 = !isSecond && Boolean(record.arrival_dt) ? new Date(record.arrival_dt) : null;
            const act1 = !isSecond && Boolean(record.act_start_time_1) ? new Date(record.act_start_time_1) : null;
            const dd2 = isSecond && Boolean(record.departure_dt2) ? new Date(record.departure_dt2) : null;
            const ad2 = isSecond && Boolean(record.arrival_dt2) ? new Date(record.arrival_dt2) : null;
            const act2 = isSecond && Boolean(record.act_start_time_2) ? new Date(record.act_start_time_2) : null;
            return {
              ...defaultRecord,
              departure_dt: Boolean(dd1) && !isNaN(dd1!.getTime())
                ? `${dd1?.getHours().toString().padStart(2, '0')}:${dd1?.getMinutes().toString().padStart(2, '0')}`
                : null,
              arrival_dt: Boolean(ad1) && !isNaN(ad1!.getTime())
                ? `${ad1?.getHours().toString().padStart(2, '0')}:${ad1?.getMinutes().toString().padStart(2, '0')}`
                : null,
              departure_dt2: Boolean(dd2) && !isNaN(dd2!.getTime())
                ? `${dd2?.getHours().toString().padStart(2, '0')}:${dd2?.getMinutes().toString().padStart(2, '0')}`
                : null,
              arrival_dt2: Boolean(ad2) && !isNaN(ad2!.getTime())
                ? `${ad2?.getHours().toString().padStart(2, '0')}:${ad2?.getMinutes().toString().padStart(2, '0')}`
                : null,
              act_start_time_1: Boolean(act1) && !isNaN(act1!.getTime())
                ? `${act1?.getHours().toString().padStart(2, '0')}:${act1?.getMinutes().toString().padStart(2, '0')}`
                : '--:--',
              act_start_time_2: Boolean(act2) && !isNaN(act2!.getTime())
                ? `${act2?.getHours().toString().padStart(2, '0')}:${act2?.getMinutes().toString().padStart(2, '0')}`
                : '--:--',
              is_second: isSecond,
              // is_late_1: isLate(dd1, ad1, act1),
              // is_late_2: isLate(dd2, ad2, act2),
              is_late_1: isShowingWhoClickedArrivalBtn ? !!+record.unarrival : !!+record.undeparture,
              is_late_2: isShowingWhoClickedArrivalBtn ? !!+record.unarrival2 : !!+record.undeparture2,
              unarrival: !isSecond ? !!+record.unarrival : !!+record.unarrival2,
              undeparture: !isSecond ? !!+record.undeparture : !!+record.undeparture2,
              당일점검: record.당일점검,
              당일점검2: record.당일점검2,
              시험후정리: record['시험후정리'],
              시험후정리2: record['시험후정리2'],
              agent_name_1: record['agent_name_1'],
              agent_name_2: record['agent_name_2'],
              agent_tel_1: record['agent_tel_1'],
              agent_tel_2: record['agent_tel_2'],
              team_code: parseInt(record['team_code']),
              team_code2: parseInt(record['team_code2']),
              '1교시완료': record['1교시완료'],
              '1st_status_code_1': Boolean(record['1st_status_code_1']) ? record['1st_status_code_1'] : '',
              '1st_status_code_2': Boolean(record['1st_status_code_2']) ? record['1st_status_code_2'] : '',
              '1st_detail_1': Boolean(record['1st_detail_1']) ? record['1st_detail_1'] : '',
              '1st_detail_2': Boolean(record['1st_detail_2']) ? record['1st_detail_2'] : '',
              '2nd_status_code_1': Boolean(record['2nd_status_code_1']) ? record['2nd_status_code_1'] : '',
              '2nd_status_code_2': Boolean(record['2nd_status_code_2']) ? record['2nd_status_code_2'] : '',
              '2nd_detail_1': Boolean(record['2nd_detail_1']) ? record['2nd_detail_1'] : '',
              '2nd_detail_2': Boolean(record['2nd_detail_2']) ? record['2nd_detail_2'] : '',
              '3rd_status_code_1': Boolean(record['3rd_status_code_1']) ? record['3rd_status_code_1'] : '',
              '3rd_status_code_2': Boolean(record['3rd_status_code_2']) ? record['3rd_status_code_2'] : '',
              '3rd_detail_1': Boolean(record['3rd_detail_1']) ? record['3rd_detail_1'] : '',
              '3rd_detail_2': Boolean(record['3rd_detail_2']) ? record['3rd_detail_2'] : '',
              '4th_status_code_1': Boolean(record['4th_status_code_1']) ? record['4th_status_code_1'] : '',
              '4th_status_code_2': Boolean(record['4th_status_code_2']) ? record['4th_status_code_2'] : '',
              '4th_detail_1': Boolean(record['4th_detail_1']) ? record['4th_detail_1'] : '',
              '4th_detail_2': Boolean(record['4th_detail_2']) ? record['4th_detail_2'] : '',
            };
          default:
            return record;
        }
      }).filter((record: any) => {
        switch (completeLevel) {
          case 0:
            return (
              (!record.is_second && record.is_late_1) || (record.is_second && record.is_late_2)
            )
          case 1:
            switch (inspectLevel) {
              case 0:
                return record.사전점검 === '완료';
              case 1:
                return record.최적화 === '완료';
              case 2:
                return (!record.is_second && record.당일점검 === '완료') || (record.is_second && record.당일점검2 === '완료');
              default:
                return true;
            }
          case 2:
            switch (inspectLevel) {
              case 0:
                return record.사전점검 === '미완료';
              case 1:
                return record.최적화 === '미완료';
              case 2:
                return (!record.is_second && record.당일점검 === '미완료') || (record.is_second && record.당일점검2 === '미완료');
              default:
                return true;
            }
          case 3:
            return (!record.is_second && record.시험후정리 === '완료') || (record.is_second && record.시험후정리2 === '완료');
          case 4:
            return (!record.is_second && record.시험후정리 === '미완료') || (record.is_second && record.시험후정리2 === '미완료');
          case 5:
            return (!record.is_second && record['1st_status_code_1'] === '완료') || (record.is_second && record['1st_status_code_2'] === '완료');
          case 6:
            return (!record.is_second && record['2nd_status_code_1'] === '완료') || (record.is_second && record['2nd_status_code_2'] === '완료');
          case 7:
            return (!record.is_second && record['3rd_status_code_1'] === '완료') || (record.is_second && record['3rd_status_code_2'] === '완료');
          case 8:
            return (!record.is_second && record['4th_status_code_1'] === '완료') || (record.is_second && record['4th_status_code_2'] === '완료');
          default:
            return true;
        }
      }).filter((record: any) => {
        switch (processStatus) {
          case 0:
            return true;
          case 1:
            // 온라인
            switch (period) {
              case 1:
                return (!record.is_second && record['1st_detail_1'] === '온라인' && compare(record, '1st_status_code_1')) || (record.is_second && record['1st_detail_2'] === '온라인' && compare(record, '1st_status_code_2'));
              case 2:
                return (!record.is_second && record['2nd_detail_1'] === '온라인' && compare(record, '2nd_status_code_1')) || (record.is_second && record['2nd_detail_2'] === '온라인' && compare(record, '2nd_status_code_2'));
              case 3:
                return (!record.is_second && record['3rd_detail_1'] === '온라인' && compare(record, '3rd_status_code_1')) || (record.is_second && record['3rd_detail_2'] === '온라인' && compare(record, '3rd_status_code_2'));
              case 4:
                // 고등학교 합계
                return currentSchool === '중학교'
                  ? (!record.is_second && record['4th_detail_1'] === '온라인' && compare(record, '4th_status_code_1')) || (record.is_second && record['4th_detail_2'] === '온라인' && compare(record, '4th_status_code_2'))
                  : (!record.is_second && ((record['1st_detail_1'] === '온라인' && compare(record, '1st_status_code_1'))
                  || (record['2nd_detail_1'] === '온라인' && compare(record, '2nd_status_code_1'))
                  || (record['3rd_detail_1'] === '온라인' && compare(record, '3rd_status_code_1'))
                )) || (record.is_second && ((record['1st_detail_2'] === '온라인' && compare(record, '1st_status_code_2'))
                  || (record['2nd_detail_2'] === '온라인' && compare(record, '2nd_status_code_2'))
                  || (record['3rd_detail_2'] === '온라인' && compare(record, '3rd_status_code_2'))
                ))
              case 5:
                // 중학교 합계
                return currentSchool === '중학교'
                  ? (!record.is_second && ((record['1st_detail_1'] === '온라인' && compare(record, '1st_status_code_1'))
                  || (record['2nd_detail_1'] === '온라인' && compare(record, '2nd_status_code_1'))
                  || (record['3rd_detail_1'] === '온라인' && compare(record, '3rd_status_code_1'))
                  || (record['4th_detail_1'] === '온라인' && compare(record, '4th_status_code_1'))
                )) || (record.is_second && ((record['1st_detail_2'] === '온라인' && compare(record, '1st_status_code_2'))
                  || (record['2nd_detail_2'] === '온라인' && compare(record, '2nd_status_code_2'))
                  || (record['3rd_detail_2'] === '온라인' && compare(record, '3rd_status_code_2'))
                  || (record['4th_detail_2'] === '온라인' && compare(record, '4th_status_code_2'))
                ))
                  : true;
              default:
                return true;
            }
          // 인트라넷
          case 2:
            switch (period) {
              case 1:
                return (!record.is_second && record['1st_detail_1'] === '인트라넷' && compare(record, '1st_status_code_1')) || (record.is_second && record['1st_detail_2'] === '인트라넷' && compare(record, '1st_status_code_2'));
              case 2:
                return (!record.is_second && record['2nd_detail_1'] === '인트라넷' && compare(record, '2nd_status_code_1')) || (record.is_second && record['2nd_detail_2'] === '인트라넷' && compare(record, '2nd_status_code_2'));
              case 3:
                return (!record.is_second && record['3rd_detail_1'] === '인트라넷' && compare(record, '3rd_status_code_1')) || (record.is_second && record['3rd_detail_2'] === '인트라넷' && compare(record, '3rd_status_code_2'));
              case 4:
                // 고등학교 합계
                return currentSchool === '중학교'
                  ? (!record.is_second && record['4th_detail_1'] === '인트라넷' && compare(record, '4th_status_code_1')) || (record.is_second && record['4th_detail_2'] === '인트라넷' && compare(record, '4th_status_code_2'))
                  : (!record.is_second && ((record['1st_detail_1'] === '인트라넷' && compare(record, '1st_status_code_1'))
                  || (record['2nd_detail_1'] === '인트라넷' && compare(record, '2nd_status_code_1'))
                  || (record['3rd_detail_1'] === '인트라넷' && compare(record, '3rd_status_code_1'))
                )) || (record.is_second && ((record['1st_detail_2'] === '인트라넷' && compare(record, '1st_status_code_2'))
                  || (record['2nd_detail_2'] === '인트라넷' && compare(record, '2nd_status_code_2'))
                  || (record['3rd_detail_2'] === '인트라넷' && compare(record, '3rd_status_code_2'))
                ))
              case 5:
                // 중학교 합계
                return currentSchool === '중학교'
                  ? (!record.is_second && ((record['1st_detail_1'] === '인트라넷' && compare(record, '1st_status_code_1'))
                  || (record['2nd_detail_1'] === '인트라넷' && compare(record, '2nd_status_code_1'))
                  || (record['3rd_detail_1'] === '인트라넷' && compare(record, '3rd_status_code_1'))
                  || (record['4th_detail_1'] === '인트라넷' && compare(record, '4th_status_code_1'))
                )) || (record.is_second && ((record['1st_detail_2'] === '인트라넷' && compare(record, '1st_status_code_2'))
                  || (record['2nd_detail_2'] === '인트라넷' && compare(record, '2nd_status_code_2'))
                  || (record['3rd_detail_2'] === '인트라넷' && compare(record, '3rd_status_code_2'))
                  || (record['4th_detail_2'] === '인트라넷' && compare(record, '4th_status_code_2'))
                ))
                  : true;
              default:
                return true;
            }
          case 3:
            switch (period) {
              case 1:
                return (!record.is_second && record['1st_detail_1'] === '지필' && compare(record, '1st_status_code_1')) || (record.is_second && record['1st_detail_2'] === '지필' && compare(record, '1st_status_code_2'));
              case 2:
                return (!record.is_second && record['2nd_detail_1'] === '지필' && compare(record, '2nd_status_code_1')) || (record.is_second && record['2nd_detail_2'] === '지필' && compare(record, '2nd_status_code_2'));
              case 3:
                return (!record.is_second && record['3rd_detail_1'] === '지필' && compare(record, '3rd_status_code_1')) || (record.is_second && record['3rd_detail_2'] === '지필' && compare(record, '3rd_status_code_2'));
              case 4:
                // 고등학교 합계
                return currentSchool === '중학교'
                  ? (!record.is_second && record['4th_detail_1'] === '지필' && compare(record, '4th_status_code_1'))
                  || (record.is_second && record['4th_detail_2'] === '지필' && compare(record, '4th_status_code_2'))
                  : (!record.is_second && ((record['1st_detail_1'] === '지필' && compare(record, '1st_status_code_1'))
                  || (record['2nd_detail_1'] === '지필' && compare(record, '2nd_status_code_1'))
                  || (record['3rd_detail_1'] === '지필' && compare(record, '3rd_status_code_1'))
                )) || (record.is_second && ((record['1st_detail_2'] === '지필' && compare(record, '1st_status_code_2'))
                  || (record['2nd_detail_2'] === '지필' && compare(record, '2nd_status_code_2'))
                  || (record['3rd_detail_2'] === '지필' && compare(record, '3rd_status_code_2'))
                ))
              case 5:
                // 중학교 합계
                return currentSchool === '중학교'
                  ? (!record.is_second && ((record['1st_detail_1'] === '지필' && compare(record, '1st_status_code_1'))
                  || (record['2nd_detail_1'] === '지필' && compare(record, '2nd_status_code_1'))
                  || (record['3rd_detail_1'] === '지필' && compare(record, '3rd_status_code_1'))
                  || (record['4th_detail_1'] === '지필' && compare(record, '4th_status_code_1'))
                )) || (record.is_second && ((record['1st_detail_2'] === '지필' && compare(record, '1st_status_code_2'))
                  || (record['2nd_detail_2'] === '지필' && compare(record, '2nd_status_code_2'))
                  || (record['3rd_detail_2'] === '지필' && compare(record, '3rd_status_code_2'))
                  || (record['4th_detail_2'] === '지필' && compare(record, '4th_status_code_2'))
                ))
                  : true;
              default:
                return true;
            }
          case 4:
            switch (period) {
              case 1:
                return (!record.is_second && record['1st_status_code_1'] === '완료') || (record.is_second && record['1st_status_code_2'] === '완료');
              case 2:
                return (!record.is_second && record['2nd_status_code_1'] === '완료') || (record.is_second && record['2nd_status_code_2'] === '완료');
              case 3:
                return (!record.is_second && record['3rd_status_code_1'] === '완료') || (record.is_second && record['3rd_status_code_2'] === '완료');
              case 4:
                return currentSchool === '중학교'
                  ? (!record.is_second && record['4th_status_code_1'] === '완료') || (record.is_second && record['4th_status_code_2'] === '완료')
                  : (!record.is_second && ((record['1st_status_code_1'] === '완료')
                  || (record['2nd_status_code_1'] === '완료')
                  || (record['3rd_status_code_1'] === '완료')
                )) || (record.is_second && ((record['1st_status_code_2'] === '완료')
                  || (record['2nd_status_code_2'] === '완료')
                  || (record['3rd_status_code_2'] === '완료')
                ))
              case 5:
                return currentSchool === '중학교'
                  ? (!record.is_second && ((record['1st_status_code_1'] === '완료')
                  || (record['2nd_status_code_1'] === '완료')
                  || (record['3rd_status_code_1'] === '완료')
                  || (record['4th_status_code_1'] === '완료')
                )) || (record.is_second && ((record['1st_status_code_2'] === '완료')
                  || (record['2nd_status_code_2'] === '완료')
                  || (record['3rd_status_code_2'] === '완료')
                  || (record['4th_status_code_2'] === '완료')
                ))
                  : true;
              default:
                return true;
            }
          default:
            return true;
        }
      });
      if (showAsTable) {
        tRecords.sort((a: any, b: any) => {
          if (a.team_code < b.team_code || a.team_code2 < b.team_code || a.team_code < b.team_code2 || a.team_code2 < b.team_code2) return 1;
          if (a.team_code > b.team_code || a.team_code2 > b.team_code || a.team_code > b.team_code2 || a.team_code2 > b.team_code2) return -1;

          return b['학교명'].localeCompare(a['학교명']);
        }).sort((a: any, b: any) => {
          if ((a.is_late_1 && !b.is_late_1) || (a.is_late_1 && !b.is_late_2) || (a.is_late_2 && !b.is_late_1) || (a.is_late_2 && !b.is_late_2)) return -1;
          if ((!a.is_late_1 && b.is_late_1) || (!a.is_late_1 && b.is_late_2) || (!a.is_late_2 && b.is_late_1) || (!a.is_late_2 && b.is_late_2)) return 1;

          if (a.team_code < b.team_code || a.team_code2 < b.team_code || a.team_code < b.team_code2 || a.team_code2 < b.team_code2) return -1;
          if (a.team_code > b.team_code || a.team_code2 > b.team_code || a.team_code > b.team_code2 || a.team_code2 > b.team_code2) return 1;

          return a['학교명'].localeCompare(b['학교명']);
        });
      }
      if (searchQuery !== '') {
        tRecords = tRecords.filter((r: any) => {
          if (searchItem === 'agent_name') {
            switch (inspectLevel) {
              case 0:
                return (r.agent_name_pre?.includes(searchQuery)) ?? false
              case 1:
                return (r.agent_name_opt?.includes(searchQuery)) ?? false
              case 2:
                return (r.agent_name_1?.includes(searchQuery) || r.agent_name_2?.includes(searchQuery)) ?? false
              default:
                return false;
            }
          } else if (searchItem === 'team_code') {
            switch (inspectLevel) {
              case 2:
                return (r.team_code === searchQuery || r.team_code2 === searchQuery) ?? false
              default:
                return false;
            }
          } else {
            return r[searchItem]?.includes(searchQuery) ?? false
          }
        });
      }
      setFilteredData(tRecords);
      // setPaginatedRows(applyPagination(tRecords, page, limit));
      setPaginatedRows(applyPagination(tRecords, 0, 99999));
    });
  }, [area, records, currentSchool, searchItem, searchQuery, completeLevel, showAgentStatus, isShowingWhoClickedArrivalBtn, processStatus, period, inspectLevel]);

  useEffect(() => {
    let tRecords =
      Boolean(area) ?
        records.filter((d) => d.gubun === area)[0]?.data ?? []
        : records.map(({data}) => data).flat(1).sort(({학교명: schoolNameA}, {학교명: schoolNameB}) => schoolNameA.localeCompare(schoolNameB));
    setOnTestStates(new Array(tRecords.length).fill(showAgentStatus ? 1 : 0));
    setCurrentTestType(showAgentStatus ? 1 : 0);
  }, [area, showAgentStatus])

  const getColor = (key: string) => {
    switch (key) {
      case '완료':
        return colorPalette.kice_green;
      case '미완료':
      case '대기':
        return colorPalette.red500;
      case '진행':
        return colorPalette.orange500;
      default:
        return colorPalette.gray500;
    }
  }

  const getGrids = useCallback(() => (
    <Box
      sx={{
        backgroundColor: colorPalette.background,
        p: 3,
        display: 'flex',
        gap: 2,
        flexWrap: 'wrap'
      }}
    >
      {paginatedRows.map((d: any, schoolIndex: number) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 1.5,
            background: colorPalette.white,
            border: `1px solid ${colorPalette.gray200}`,
            width: 308,
            p: 1.5,
            gap: 0.5,
          }}
        >
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 48,
          }}>
            <Typography sx={{
              ...textStyle,
              fontSize: 30,
              color: ((!d.is_second && d.is_late_1) || (d.is_second && d.is_late_2)) ? colorPalette.red500 : colorPalette.gray900,
            }}>
              {d['학교명'].length > 10 ? `${d['학교명'].slice(0, 10)}...` : d['학교명']}
            </Typography>
            {[0, 1].includes(inspectLevel) && (
              <Typography
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  p: '4px 12px',
                  borderRadius: '16px',
                  ...textStyle,
                  color: colorPalette.white,
                  fontSize: 16,
                  ...inspectLevel === 0 && ({
                    backgrogetColorundColor: d['사전점검'],
                  }),
                  ...inspectLevel === 1 && ({
                    backgrogetColorundColor: d['최적화'],
                  }),
                  // color: getColor((item.pre.cmpl / item.total) * 100),
                }}
              >
                {inspectLevel === 0 && d['사전점검']}
                {inspectLevel === 1 && d['최적화']}
              </Typography>
            )}
          </Box>
          {inspectLevel === 2 && (
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <IconButton
                sx={{p: 0}}
                onClick={(e) => {
                  setOnTestStates([
                    ...onTestStates.slice(0, schoolIndex),
                    onTestStates[schoolIndex] - 1 < 0 ? onTestTypes.length - 1 : onTestStates[schoolIndex] - 1,
                    ...onTestStates.slice(schoolIndex + 1),
                  ])
                }}>
                <ArrowLeft/>
              </IconButton>
              <Typography sx={{
                ...textStyle,
                fontSize: 16,
                height: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                {onTestTypes[onTestStates[schoolIndex]]}
              </Typography>
              <IconButton
                sx={{p: 0}}
                onClick={(e) => {
                  setOnTestStates([
                    ...onTestStates.slice(0, schoolIndex),
                    onTestStates[schoolIndex] + 1 > onTestTypes.length - 1 ? 0 : onTestStates[schoolIndex] + 1,
                    ...onTestStates.slice(schoolIndex + 1),
                  ])
                }}>
                <ArrowRight/>
              </IconButton>
            </Box>
          )}
          {([0, 1].includes(inspectLevel) || (inspectLevel === 2 && onTestStates[schoolIndex] === 0)) && (
            <Box sx={{
              flex: 1,
              m: '16px 4px 0',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'start',
              alignItems: 'center',
              background: colorPalette.gray100,
              borderRadius: 1,
              p: '6px 12px',
              gap: 1,
              cursor: 'pointer',
              ...inspectLevel === 2 && ({
                mb: 0.5,
              })
            }}
                 onClick={() => {
                   navigate('/school_detail', {
                     state: {
                       schoolData: d
                     },
                   });
                 }}
            >
              <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between'
              }}>
                <Typography sx={{
                  ...textStyle,
                  color: colorPalette.gray500,
                  fontSize: 16,
                }}>
                  지역
                </Typography>
                <Box sx={{
                  ...textStyle,
                  color: colorPalette.gray700,
                  fontSize: 16,
                  fontWeight: 400,
                }}>
                  {Boolean(area) ? area : d['지역']}
                </Box>
              </Box>
              <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between'
              }}>
                <Typography sx={{
                  ...textStyle,
                  color: colorPalette.gray500,
                  fontSize: 16,
                }}>
                  학교 Tel.
                </Typography>
                <Box sx={{
                  ...textStyle,
                  color: colorPalette.gray700,
                  fontSize: 16,
                  fontWeight: 400,
                }}>
                  {d['학교연락처']}
                </Box>
              </Box>
              <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between'
              }}>
                <Typography sx={{
                  ...textStyle,
                  color: colorPalette.gray500,
                  fontSize: 16,
                }}>
                  교사 Tel.
                </Typography>
                <Box sx={{
                  ...textStyle,
                  color: colorPalette.gray700,
                  fontSize: 16,
                  fontWeight: 400,
                }}>
                  {d['학교선생님연락처']}
                </Box>
              </Box>
              <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flex: 1,
                mb: 0.5,
              }}>
                <Typography sx={{
                  ...textStyle,
                  color: colorPalette.gray500,
                  fontSize: 16,
                }}>
                  주소
                </Typography>
                <Box sx={{
                  ...textStyle,
                  color: colorPalette.gray700,
                  fontSize: 16,
                  fontWeight: 400,
                  whiteSpace: 'pre-wrap',
                  maxWidth: 188,
                  textAlign: 'right'
                }}>
                  {d['학교주소']}
                </Box>
              </Box>
            </Box>
          )}
          {([0, 1].includes(inspectLevel) && ((): boolean => {
            const agentInfo: any = agents?.find(({job02_pk}: { job02_pk: number }) => job02_pk === d.job02_pk) ?? {}
            return agentInfo?.agent_name && agentInfo?.agent_tel;
          })()) && (
            // 요원 정보
            <>
              <Box sx={{
                m: '0 4px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                background: colorPalette.gray100,
                borderRadius: 1,
                p: '6px 12px',
                gap: 1,
                cursor: 'pointer',
              }}
                   onClick={() => {
                     navigate('/school_detail', {
                       state: {
                         schoolData: d
                       },
                     });
                   }}
              >
                <Box sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}>
                  <Typography sx={{
                    ...textStyle,
                    color: colorPalette.gray500,
                    fontSize: 16,
                  }}>
                    요원
                  </Typography>
                  <Box sx={{
                    ...textStyle,
                    color: colorPalette.gray700,
                    fontSize: 16,
                    fontWeight: 400,
                  }}>
                    {`${(agents.find(({job02_pk}: {
                      job02_pk: number
                    }) => job02_pk === d.job02_pk) as any).agent_name} (${(agents.find(({job02_pk}: {
                      job02_pk: number
                    }) => job02_pk === d.job02_pk) as any).agent_tel})`}
                  </Box>
                </Box>
              </Box>
            </>
          )}
          {(inspectLevel === 2 && onTestStates[schoolIndex] === 1 && !d.is_second) && (
            <Box sx={{
              m: '16px 4px',
              display: 'flex',
              flexDirection: 'column',
              background: colorPalette.gray100,
              borderRadius: 1,
              p: '10px 12px',
              gap: 1,
              flex: 1,
              mb: 0.5,
              cursor: 'pointer',
              ...(!parsedToken.authorized) && ({
                justifyContent: 'center'
              })
            }}
            >
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
                   onClick={() => {
                     navigate('/school_detail', {
                       state: {
                         schoolData: d
                       },
                     });
                   }}>
                <Typography sx={{
                  ...textStyle,
                  color: colorPalette.gray500,
                  fontSize: 16,
                }}>
                  요원
                </Typography>
                {(Boolean(d.agent_name_1) && Boolean(d.agent_tel_1)) && (
                  <Box sx={{
                    ...textStyle,
                    color: colorPalette.gray700,
                    fontSize: 16,
                    fontWeight: 400,
                  }}>
                    {`${d.agent_name_1} (${d.agent_tel_1})`}
                  </Box>
                )}
              </Box>
              <Typography sx={{
                ...textStyle,
                color: colorPalette.gray700,
                fontSize: 16,
                textAlign: 'center',
              }}
                          onClick={() => {
                            navigate('/school_detail', {
                              state: {
                                schoolData: d
                              },
                            });
                          }}
              >
                {`출발 ${Boolean(d.departure_dt) ? d.departure_dt : '--:--'} ~ 도착 ${Boolean(d.arrival_dt) ? d.arrival_dt : '--:--'}`}
              </Typography>
              <Typography sx={{
                ...textStyle,
                color: colorPalette.gray700,
                fontSize: 16,
                textAlign: 'center'
              }}
                          onClick={() => {
                            navigate('/school_detail', {
                              state: {
                                schoolData: d
                              },
                            });
                          }}
              >
                {`1교시 ${d.act_start_time_1}`}
              </Typography>
              {(!isShowingWhoClickedArrivalBtn && parsedToken.authorized) && (
                <Box sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  gap: 0.5,
                }}>
                  {/*<CommonBtn*/}
                  {/*  etcSx={{*/}
                  {/*    width: '130px',*/}
                  {/*    borderRadius: 1*/}
                  {/*  }} title={'초기화'} color={colorPalette.white} bgColor={colorPalette.blue500}*/}
                  {/*  onClick={async (e) => {*/}
                  {/*    const {data} = await client.get(`/reset_departure_time?pk=${d.job02_pk}&is_second=${+d.is_second}`);*/}
                  {/*    if (data.result === "ok") {*/}
                  {/*      setPopupTitle("알림");*/}
                  {/*      setPopupContent("해당 요원의 시간정보가 초기화 되었습니다.\n(반영에 시간이 소요될 수 있습니다.)");*/}
                  {/*      setIsPopupOpen(true);*/}
                  {/*    }*/}
                  {/*  }}*/}
                  {/*/>*/}
                  {/*<CommonBtn etcSx={{*/}
                  {/*  width: '130px',*/}
                  {/*  borderRadius: 1*/}
                  {/*}} title={'1교시 시작시간 입력'} color={colorPalette.white} bgColor={colorPalette.orange400}/>*/}
                </Box>
              )}
            </Box>
          )}
          {(inspectLevel === 2 && onTestStates[schoolIndex] === 1 && d.is_second) && (
            <Box sx={{
              m: '16px 4px',
              display: 'flex',
              flexDirection: 'column',
              background: colorPalette.gray100,
              borderRadius: 1,
              p: '10px 12px',
              gap: 1,
              flex: 1,
              mb: 0.5,
              cursor: 'pointer'
            }}
            >
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
                   onClick={() => {
                     navigate('/school_detail', {
                       state: {
                         schoolData: d
                       },
                     });
                   }}>
                <Typography sx={{
                  ...textStyle,
                  color: colorPalette.gray500,
                  fontSize: 16,
                }}>
                  요원
                </Typography>
                {(Boolean(d.agent_name_2) && Boolean(d.agent_tel_2)) && (
                  <Box sx={{
                    ...textStyle,
                    color: colorPalette.gray700,
                    fontSize: 16,
                    fontWeight: 400,
                  }}>
                    {`${d.agent_name_2} (${d.agent_tel_2})`}
                  </Box>
                )}
              </Box>
              <Typography sx={{
                ...textStyle,
                color: colorPalette.gray700,
                fontSize: 16,
                textAlign: 'center',
              }}
                          onClick={() => {
                            navigate('/school_detail', {
                              state: {
                                schoolData: d
                              },
                            });
                          }}
              >
                {`출발 ${Boolean(d.departure_dt2) ? d.departure_dt2 : '--:--'} ~ 도착 ${Boolean(d.arrival_dt2) ? d.arrival_dt2 : '--:--'}`}
              </Typography>
              <Typography sx={{
                ...textStyle,
                color: colorPalette.gray700,
                fontSize: 16,
                textAlign: 'center'
              }}
                          onClick={() => {
                            navigate('/school_detail', {
                              state: {
                                schoolData: d
                              },
                            });
                          }}
              >
                {`1교시 ${d.act_start_time_2}`}
              </Typography>
              {!isShowingWhoClickedArrivalBtn && parsedToken.authorized && (
                <Box sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  gap: 0.5,
                }}>
                  {/*<CommonBtn*/}
                  {/*  etcSx={{*/}
                  {/*    width: '130px',*/}
                  {/*    borderRadius: 1*/}
                  {/*  }} title={'초기화'} color={colorPalette.white} bgColor={colorPalette.blue500}*/}
                  {/*  onClick={async (e) => {*/}
                  {/*    const {data} = await client.get(`/reset_departure_time?pk=${d.job02_pk}&is_second=${+d.is_second}`);*/}
                  {/*    if (data.result === "ok") {*/}
                  {/*      setPopupTitle("알림");*/}
                  {/*      setPopupContent("해당 요원의 시간정보가 초기화 되었습니다.\n(반영에 시간이 소요될 수 있습니다.)");*/}
                  {/*      setIsPopupOpen(true);*/}
                  {/*    }*/}
                  {/*  }}*/}
                  {/*/>*/}
                  {/*<CommonBtn etcSx={{*/}
                  {/*  width: '130px',*/}
                  {/*  borderRadius: 1*/}
                  {/*}} title={'1교시 시작시간 입력'} color={colorPalette.white} bgColor={colorPalette.orange400}/>*/}
                </Box>
              )}
            </Box>
          )}
          {(inspectLevel === 2 && onTestStates[schoolIndex] === 2 && !d.is_second) && (
            <Box sx={{
              gap: 0.5,
              flex: 1,
              m: '16px 4px 4px',
              borderRadius: 0.5,
              backgroundColor: colorPalette.gray100,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              ...textStyle,
              fontSize: 48,
              fontWeight: 700,
              minHeight: 156,
              color: getColor(d['당일점검']),
              cursor: 'pointer'
            }}
                 onClick={() => {
                   navigate('/school_detail', {
                     state: {
                       schoolData: d
                     },
                   });
                 }}
            >
              {d['당일점검']}
            </Box>
          )}
          {(inspectLevel === 2 && onTestStates[schoolIndex] === 2 && d.is_second) && (
            <Box sx={{
              gap: 0.5,
              flex: 1,
              m: '16px 4px 4px',
              borderRadius: 0.5,
              backgroundColor: colorPalette.gray100,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              ...textStyle,
              fontSize: 48,
              fontWeight: 700,
              minHeight: 156,
              color: getColor(d['당일점검2']),
              cursor: 'pointer'
            }}
                 onClick={() => {
                   navigate('/school_detail', {
                     state: {
                       schoolData: d
                     },
                   });
                 }}
            >
              {d['당일점검2']}
            </Box>
          )}
          {(inspectLevel === 2 && onTestStates[schoolIndex] === 3 && !d.is_second) && (
            <Box sx={{
              flex: 1,
              mt: 2,
              mx: 0.5,
              mb: 0.5,
              display: 'flex',
              flexDirection: 'column',
              gap: 0.5,
              ...textStyle,
              fontSize: 48,
              fontWeight: 700,
              minHeight: 156,
              cursor: 'pointer'
            }}
                 onClick={() => {
                   navigate('/school_detail', {
                     state: {
                       schoolData: d
                     },
                   });
                 }}>
              <Box sx={{
                display: 'flex',
                gap: 0.5,
                flex: 1,
              }}>
                <Box sx={{
                  flex: 1,
                  p: '6px 12px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  borderRadius: 0.5,
                  backgroundColor: colorPalette.gray100,
                }}>
                  <Typography sx={{
                    ...textStyle,
                    fontSize: 16,
                  }}>
                    {`1교시${d['1st_status_code_1'] === '완료' ? ` (${d['1st_detail_1']})` : ''}`}
                  </Typography>
                  <Typography sx={{
                    width: '100%',
                    textAlign: 'right',
                    ...textStyle,
                    fontSize: 36,
                    fontWeight: 600,
                    lineHeight: 1.2,
                    color: getColor(d['1st_status_code_1'])
                  }}>
                    {d['1st_status_code_1']}
                  </Typography>
                </Box>
                <Box sx={{
                  flex: 1,
                  p: '6px 12px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 0.5,
                  justifyContent: 'space-between',
                  borderRadius: 0.5,
                  backgroundColor: colorPalette.gray100,
                }}>
                  <Typography sx={{
                    ...textStyle,
                    fontSize: 16,
                  }}>
                    {`2교시${d['2nd_status_code_1'] === '완료' ? ` (${d['2nd_detail_1']})` : ''}`}
                  </Typography>
                  <Typography sx={{
                    width: '100%',
                    textAlign: 'right',
                    ...textStyle,
                    fontSize: 36,
                    fontWeight: 600,
                    lineHeight: 1.2,
                    color: getColor(d['2nd_status_code_1'])
                  }}>
                    {d['2nd_status_code_1']}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{
                display: 'flex',
                gap: 0.5,
                flex: 1,
              }}>
                <Box sx={{
                  flex: 1,
                  p: '6px 12px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  borderRadius: 0.5,
                  backgroundColor: colorPalette.gray100,
                }}>
                  <Typography sx={{
                    ...textStyle,
                    fontSize: 16,
                  }}>
                    {`3교시${d['3rd_status_code_1'] === '완료' ? ` (${d['3rd_detail_1']})` : ''}`}
                  </Typography>
                  <Typography sx={{
                    width: '100%',
                    textAlign: 'right',
                    ...textStyle,
                    fontSize: 36,
                    fontWeight: 600,
                    lineHeight: 1.2,
                    color: getColor(d['3rd_status_code_1'])
                  }}>
                    {d['3rd_status_code_1']}
                  </Typography>
                </Box>
                <Box sx={{
                  flex: 1,
                  p: '6px 12px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 0.5,
                  justifyContent: 'space-between',
                  borderRadius: 0.5,
                  backgroundColor: colorPalette.gray100,
                }}>
                  <Typography sx={{
                    ...textStyle,
                    fontSize: 16,
                  }}>
                    {`4교시${d['4th_status_code_1'] === '완료' ? ` (${d['4th_detail_1']})` : ''}`}
                  </Typography>
                  <Typography sx={{
                    width: '100%',
                    textAlign: 'right',
                    ...textStyle,
                    fontSize: 36,
                    fontWeight: 600,
                    lineHeight: 1.2,
                    color: getColor(currentSchool === '고등학교' ? '-' : d['4th_status_code_1'])
                  }}>
                    {currentSchool === '고등학교' ? '-' : d['4th_status_code_1']}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          {(inspectLevel === 2 && onTestStates[schoolIndex] === 3 && d.is_second) && (
            <Box sx={{
              flex: 1,
              mt: 2,
              mx: 0.5,
              mb: 0.5,
              display: 'flex',
              flexDirection: 'column',
              gap: 0.5,
              ...textStyle,
              fontSize: 48,
              fontWeight: 700,
              minHeight: 156,
              color: getColor(d['당일점검']),
              cursor: 'pointer'
            }}
                 onClick={() => {
                   navigate('/school_detail', {
                     state: {
                       schoolData: d
                     },
                   });
                 }}>
              <Box sx={{
                display: 'flex',
                gap: 0.5,
                flex: 1,
              }}>
                <Box sx={{
                  flex: 1,
                  p: '6px 12px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  borderRadius: 0.5,
                  backgroundColor: colorPalette.gray100,
                }}>
                  <Typography sx={{
                    ...textStyle,
                    fontSize: 16,
                  }}>
                    {`1교시${d['1st_status_code_2'] === '완료' ? ` (${d['1st_detail_2']})` : ''}`}
                  </Typography>
                  <Typography sx={{
                    width: '100%',
                    textAlign: 'right',
                    ...textStyle,
                    fontSize: 36,
                    fontWeight: 600,
                    lineHeight: 1.2,
                    color: getColor(d['1st_status_code_2'])
                  }}>
                    {d['1st_status_code_2']}
                  </Typography>
                </Box>
                <Box sx={{
                  flex: 1,
                  p: '6px 12px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 0.5,
                  justifyContent: 'space-between',
                  borderRadius: 0.5,
                  backgroundColor: colorPalette.gray100,
                }}>
                  <Typography sx={{
                    ...textStyle,
                    fontSize: 16,
                  }}>
                    {`2교시${d['2nd_status_code_2'] === '완료' ? ` (${d['2nd_detail_2']})` : ''}`}
                  </Typography>
                  <Typography sx={{
                    width: '100%',
                    textAlign: 'right',
                    ...textStyle,
                    fontSize: 36,
                    fontWeight: 600,
                    lineHeight: 1.2,
                    color: getColor(d['2nd_status_code_2'])
                  }}>
                    {d['2nd_status_code_2']}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{
                display: 'flex',
                gap: 0.5,
                flex: 1,
              }}>
                <Box sx={{
                  flex: 1,
                  p: '6px 12px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  borderRadius: 0.5,
                  backgroundColor: colorPalette.gray100,
                }}>
                  <Typography sx={{
                    ...textStyle,
                    fontSize: 16,
                  }}>
                    {`3교시${d['3rd_status_code_2'] === '완료' ? ` (${d['3rd_detail_2']})` : ''}`}
                  </Typography>
                  <Typography sx={{
                    width: '100%',
                    textAlign: 'right',
                    ...textStyle,
                    fontSize: 36,
                    fontWeight: 600,
                    lineHeight: 1.2,
                    color: getColor(d['3rd_status_code_2'])
                  }}>
                    {d['3rd_status_code_2']}
                  </Typography>
                </Box>
                <Box sx={{
                  flex: 1,
                  p: '6px 12px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 0.5,
                  justifyContent: 'space-between',
                  borderRadius: 0.5,
                  backgroundColor: colorPalette.gray100,
                }}>
                  <Typography sx={{
                    ...textStyle,
                    fontSize: 16,
                  }}>
                    {`4교시${d['4th_status_code_2'] === '완료' ? ` (${d['4th_detail_2']})` : ''}`}
                  </Typography>
                  <Typography sx={{
                    width: '100%',
                    textAlign: 'right',
                    ...textStyle,
                    fontSize: 36,
                    fontWeight: 600,
                    lineHeight: 1.2,
                    color: getColor(d['4th_status_code_2'])
                  }}>
                    {d['4th_status_code_2']}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          {(inspectLevel === 2 && onTestStates[schoolIndex] === 4) && (
            <Box sx={{
              gap: 0.5,
              flex: 1,
              m: '16px 4px 4px',
              borderRadius: 0.5,
              backgroundColor: colorPalette.gray100,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              ...textStyle,
              fontSize: 48,
              fontWeight: 700,
              minHeight: 156,
              color: getColor(d['시험후정리']),
              cursor: 'pointer'
            }}
                 onClick={() => {
                   navigate('/school_detail', {
                     state: {
                       schoolData: d
                     },
                   });
                 }}>
              {d['시험후정리']}
            </Box>
          )}
        </Box>
      ))}
    </Box>
  ), [agents, area, currentSchool, inspectLevel, navigate, onTestStates, onTestTypes, paginatedRows, setIsPopupOpen, setPopupContent, setPopupTitle, isShowingWhoClickedArrivalBtn, textStyle])

  const defaultFont = {
    color: colorPalette.gray900,
    fontFamily: defaultFontFamily,
    fontSize: 18,
  };

  const getTable = useCallback(() => (
    <Box
      sx={{
        p: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 0.5,
      }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: 1,
      }}>
        <IconButton
          sx={{
            p: 0
          }}
          onClick={(e) => {
            let newTypeCode = currentTestType - 1;
            if (newTypeCode < 0) newTypeCode = 2;
            setCurrentTestType(newTypeCode)
          }}>
          <ArrowLeft/>
        </IconButton>
        <Typography sx={{
          ...textStyle,
          fontSize: 16,
          height: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          {(() => {
            switch(currentTestType) {
              case 0: return '학교정보';
              case 1: return '요원정보';
              case 2: return '진행상황';
              default: return '';
            }
          })()}
        </Typography>
        <IconButton
          sx={{
            p: 0
          }}
          onClick={(e) => {
            setCurrentTestType((currentTestType + 1) % 3)
          }}>
          <ArrowRight/>
        </IconButton>
      </Box>
      <TableContainer>
        <Table>
          {currentTestType === 0 && (
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    ...defaultFont,
                    fontSize: 18
                  }}>
                  조
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    ...defaultFont,
                    fontSize: 18
                  }}>
                  학교명
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    ...defaultFont,
                    fontSize: 18
                  }}>
                  학교 Tel.
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    ...defaultFont,
                    fontSize: 18
                  }}>
                  교사 Tel.
                </TableCell>
                <TableCell align="center" sx={{
                  ...defaultFont,
                  fontSize: 18
                }}>
                  주소
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          {currentTestType === 1 && (
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    ...defaultFont,
                    fontSize: 18
                  }}>
                  조
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    ...defaultFont,
                    fontSize: 18
                  }}>
                  학교명
                </TableCell>
                <TableCell align="center" sx={{
                  ...defaultFont,
                  fontSize: 18
                }}>
                  요원정보
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    ...defaultFont,
                    fontSize: 18
                  }}>
                  출발시간
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    ...defaultFont,
                    fontSize: 18
                  }}>
                  도착시간
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    ...defaultFont,
                    fontSize: 18
                  }}>
                  1교시시작시간
                </TableCell>
                {/*<TableCell*/}
                {/*  align="center"*/}
                {/*  sx={{*/}
                {/*    ...defaultFont,*/}
                {/*    fontSize: 18*/}
                {/*  }}>*/}
                {/*  시간정보 초기화*/}
                {/*</TableCell>*/}
              </TableRow>
            </TableHead>
          )}
          {currentTestType === 2 && (
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    ...defaultFont,
                    fontSize: 18
                  }}>
                  조
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    ...defaultFont,
                    fontSize: 18
                  }}>
                  학교명
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    ...defaultFont,
                    fontSize: 18
                  }}>당일점검</TableCell>
                <TableCell
                  align="center"
                  sx={{
                    ...defaultFont,
                    fontSize: 18
                  }}>1교시</TableCell>
                <TableCell
                  align="center"
                  sx={{
                    ...defaultFont,
                    fontSize: 18
                  }}>2교시</TableCell>
                <TableCell
                  align="center"
                  sx={{
                    ...defaultFont,
                    fontSize: 18
                  }}>3교시</TableCell>
                {currentSchool === '중학교' && (
                  <TableCell
                    align="center"
                    sx={{
                      ...defaultFont,
                      fontSize: 18
                    }}>
                    4교시
                  </TableCell>
                )}
                <TableCell
                  align="center"
                  sx={{
                    ...defaultFont,
                    fontSize: 18
                  }}>마무리</TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {currentTestType === 0 && paginatedRows.map((row: any) => (
              <TableRow sx={{
                backgroundColor: (() => {
                  if (row.undeparture) return colorPalette.yellow500;
                  else if (row.unarrival) return colorPalette.orange400;
                  else return colorPalette.white;
                })(),
                cursor: 'pointer',
              }} onClick={(e) => {
                navigate('/school_detail', {
                  state: {
                    schoolData: row
                  },
                });
              }}>
                <TableCell align="center" sx={{
                  p: '16px !important',
                  ...defaultFont,
                  fontSize: 16
                }}>
                  {!row.is_second
                    ? Boolean(row.team_code) ? `${row.team_code}조` : '-'
                    : Boolean(row.team_code2) ? `${row.team_code2}조` : '-'}
                </TableCell>
                <TableCell sx={{
                  p: '16px !important',
                  ...defaultFont,
                  fontSize: 16,
                }}>
                  {row['학교명']}
                </TableCell>
                <TableCell align="center" sx={{
                  p: '16px !important',
                  ...defaultFont,
                  fontSize: 16
                }}>
                  {row['학교연락처']}
                </TableCell>
                <TableCell align="center" sx={{
                  p: '16px !important',
                  ...defaultFont,
                  fontSize: 16
                }}>
                  {row['학교선생님연락처']}
                </TableCell>
                <TableCell sx={{
                  p: '16px !important',
                  ...defaultFont,
                  fontSize: 16
                }}>
                  {row['학교주소']}
                </TableCell>
              </TableRow>
            ))}
            {currentTestType === 1 && paginatedRows.map((row: any) => (
              <TableRow sx={{
                backgroundColor: (() => {
                  if (row.undeparture) return colorPalette.yellow500;
                  else if (row.unarrival) return colorPalette.orange400;
                  else return colorPalette.white;
                })(),
                cursor: 'pointer',
              }} onClick={(e) => {
                navigate('/school_detail', {
                  state: {
                    schoolData: row
                  },
                });
              }}>
                <TableCell align="center" sx={{
                  p: '16px !important',
                  ...defaultFont,
                  fontSize: 16
                }}>
                  {!row.is_second
                    ? Boolean(row.team_code) ? `${row.team_code}조` : '-'
                    : Boolean(row.team_code2) ? `${row.team_code2}조` : '-'}
                </TableCell>
                <TableCell sx={{
                  p: '16px !important',
                  ...defaultFont,
                  fontSize: 16,
                }}>
                  {row['학교명']}
                </TableCell>
                <TableCell align="center" sx={{
                  p: '16px !important',
                  ...defaultFont,
                  fontSize: 16
                }}>
                  {!row.is_second
                    ? (Boolean(row.agent_name_1) && Boolean(row.agent_tel_1)) ? `${row.agent_name_1} (${row.agent_tel_1})` : '-'
                    : (Boolean(row.agent_name_2) && Boolean(row.agent_tel_2)) ? `${row.agent_name_2} (${row.agent_tel_2})` : '-'}
                </TableCell>
                <TableCell align="center" sx={{
                  p: '16px !important',
                  ...defaultFont,
                  fontSize: 16
                }}>
                  {!row.is_second
                    ? Boolean(row.departure_dt) ? row.departure_dt : '--:--'
                    : Boolean(row.departure_dt2) ? row.departure_dt2 : '--:--'}
                </TableCell>
                <TableCell align="center" sx={{
                  p: '16px !important',
                  ...defaultFont,
                  fontSize: 16
                }}>
                  {!row.is_second
                    ? Boolean(row.arrival_dt) ? row.arrival_dt : '--:--'
                    : Boolean(row.arrival_dt2) ? row.arrival_dt2 : '--:--'}
                </TableCell>
                <TableCell align="center" sx={{
                  p: '16px !important',
                  ...defaultFont,
                  fontSize: 16
                }}>
                  {!row.is_second
                    ? Boolean(row.act_start_time_1) ? row.act_start_time_1 : '--:--'
                    : Boolean(row.act_start_time_2) ? row.act_start_time_2 : '--:--'}
                </TableCell>
                {/*<TableCell>*/}
                {/*  <Box sx={{*/}
                {/*    width: '100%',*/}
                {/*    display: 'flex',*/}
                {/*    justifyContent: 'center',*/}
                {/*    gap: 0.5,*/}
                {/*  }}>*/}
                {/*    <CommonBtn*/}
                {/*      etcSx={{*/}
                {/*        width: '130px',*/}
                {/*        borderRadius: 1*/}
                {/*      }} title={'초기화'} color={colorPalette.white} bgColor={colorPalette.blue500}*/}
                {/*      onClick={async (e) => {*/}
                {/*        const {data} = await client.get(`/reset_departure_time?pk=${row.job02_pk}&is_second=${+row.is_second}`);*/}
                {/*        if (data.result === "ok") {*/}
                {/*          setPopupTitle("알림");*/}
                {/*          setPopupContent("해당 요원의 시간정보가 초기화 되었습니다.\n(반영에 시간이 소요될 수 있습니다.)");*/}
                {/*          setIsPopupOpen(true);*/}
                {/*        }*/}
                {/*      }}*/}
                {/*    />*/}
                {/*  </Box>*/}
                {/*</TableCell>*/}
              </TableRow>
            ))}
            {currentTestType === 2 && paginatedRows.map((row: any) => (
              <TableRow sx={{
                backgroundColor: (() => {
                  if (row.undeparture) return colorPalette.yellow500;
                  else if (row.unarrival) return colorPalette.orange400;
                  else return colorPalette.white;
                })(),
                cursor: 'pointer',
              }} onClick={(e) => {
                navigate('/school_detail', {
                  state: {
                    schoolData: row
                  },
                });
              }}>
                <TableCell align="center" sx={{
                  p: '16px !important',
                  ...defaultFont,
                  fontSize: 16
                }}>
                  {!row.is_second
                    ? Boolean(row.team_code) ? `${row.team_code}조` : '-'
                    : Boolean(row.team_code2) ? `${row.team_code2}조` : '-'}
                </TableCell>
                <TableCell sx={{
                  p: '16px !important',
                  ...defaultFont,
                  fontSize: 16,
                }}>
                  {row['학교명']}
                </TableCell>
                <TableCell align="center" sx={{
                  p: '16px !important',
                  ...defaultFont,
                  fontSize: 16
                }}>
                  {!row.is_second
                    ? Boolean(row.당일점검) ? row.당일점검 : '-'
                    : Boolean(row.당일점검2) ? row.당일점검2 : '-'}
                </TableCell>
                <TableCell align="center" sx={{
                  p: '16px !important',
                  ...defaultFont,
                  fontSize: 16
                }}>
                  {!row.is_second
                    ? Boolean(row['1st_status_code_1']) ? row['1st_status_code_1'] : '-'
                    : Boolean(row['1st_status_code_2']) ? row['1st_status_code_2'] : '-'}
                </TableCell>
                <TableCell align="center" sx={{
                  p: '16px !important',
                  ...defaultFont,
                  fontSize: 16
                }}>
                  {!row.is_second
                    ? Boolean(row['2nd_status_code_1']) ? row['2nd_status_code_1'] : '-'
                    : Boolean(row['2nd_status_code_2']) ? row['2nd_status_code_2'] : '-'}
                </TableCell>
                <TableCell align="center" sx={{
                  p: '16px !important',
                  ...defaultFont,
                  fontSize: 16
                }}>
                  {!row.is_second
                    ? Boolean(row['3rd_status_code_1']) ? row['3rd_status_code_1'] : '-'
                    : Boolean(row['3rd_status_code_2']) ? row['3rd_status_code_2'] : '-'}
                </TableCell>
                {currentSchool === '중학교' && (
                  <TableCell align="center" sx={{
                    p: '16px !important',
                    ...defaultFont,
                  fontSize: 16
                  }}>
                    {!row.is_second
                      ? Boolean(row['4th_status_code_1']) ? row['4th_status_code_1'] : '-'
                      : Boolean(row['4th_status_code_2']) ? row['4th_status_code_2'] : '-'}
                  </TableCell>
                )}
                <TableCell align="center" sx={{
                  p: '16px !important',
                  ...defaultFont,
                  fontSize: 16
                }}>
                  {!row.is_second
                    ? Boolean(row['시험후정리']) ? row['시험후정리'] : '-'
                    : Boolean(row['시험후정리2']) ? row['시험후정리2'] : '-'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  ), [currentSchool, currentTestType, defaultFont, navigate, paginatedRows])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        background: colorPalette.background,
        minHeight: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          backgroundColor: colorPalette.background,
          borderBottom: `1px solid ${colorPalette.gray200}`
        }}
      >
        <Box>
          <Box display="flex" alignItems='center' height={60} width="calc(100% - 267px)" sx={{
            p: '8px 24px',
            gap: 2.5,
            flex: 1,
          }}>
            <IconButton sx={{p: 0}} onClick={(e) => {
              setProcessStatus(0);
              setPeriod(0);
              navigate(-1);
            }}>
              <BackButton/>
            </IconButton>
            <Typography
              sx={{
                fontFamily: defaultFontFamily,
                fontSize: 24,
                fontWeight: 600,
                color: colorPalette.gray900
              }}
            >
              {Boolean(area) ? area : '전체'}
            </Typography>
            <Box sx={{width: '1px', height: 24, background: colorPalette.gray500}}/>
            <Box
              sx={{
                display: 'flex',
                width: 96,
              }}
            >
              <SelectBox
                fullWidth
                underline={false}
                wrapperSx={{
                  width: 96
                }}
                etcSx={{
                  color: colorPalette.gray900,
                  fontFamily: defaultFontFamily,
                  fontSize: 18,
                  fontWeight: 400
                }}
                inputMode
                currentConditionKey={searchItem}
                onChange={(e) => {
                  setSearchItem(e.target.value);
                }}
                children={searchItems}
              />
            </Box>
            <InputField sx={{
              width: 300
            }} value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}/>
          </Box>
        </Box>
      </Box>
      {!([0, 1].includes(inspectLevel)) && showAsTable
        ? getTable()
        : getGrids()}
    </Box>
  );
}

export default DashboardDetail;
