import {Box, CircularProgress} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import SideBar from './components/Sidebar';
import DashboardMain from './pages/dashboard';
import DashboardDetail from './pages/dashboard/detail';
import SchoolDetail from './pages/statistics/detail';
import Statistics from './pages/statistics';
import {
  disconnectSocket,
  initSocketConnection,
  socket,
} from './network.config';
import LoginPage from './pages/login';
import {colorPalette, headerHeight, sidebarWidth} from "./const";
import {parseJwt} from "./util";
import SimpleDialog from "./components/SimpleDialog";

export interface FutureNuriCnt {
  login?: number | null;
  unlogin?: number | null;
  prd1OnlineCnt?: number | null;
  prd1CompleteCnt?: number | null;
  prd2OnlineCnt?: number | null;
  prd2CompleteCnt?: number | null;
  prd3OnlineCnt?: number | null;
  prd3CompleteCnt?: number | null;
  prd4OnlineCnt?: number | null;
  prd4CompleteCnt?: number | null;
}

export default function App() {
  const navigate = useNavigate();
  // 중학교
  // 대시보드
  const [items, setItems] = useState([]);
  // 학교별 상세데이터
  const [detailItems, setDetailItems] = useState([]);
  // 학교별 상세데이터 (당일 임시데이터)
  const [tempDetailItemsOndate, setTempDetailItemsOndate] = useState([]);
  // 학교별 상세데이터(당일)
  const [detailItemsOndate, setDetailItemsOndate] = useState([]);
  // 지역별 통계 데이터
  const [statisticsAreaItems, setStatisticsAreaItems] = useState([]);
  // 임시 통계 데이터
  const [tempStatisticsItems, setTempStatisticsItems] = useState([]);
  // 상세 통계 데이터
  const [statisticsItems, setStatisticsItems] = useState([]);

  // 고등학교
  const [hsItems, setHsItems] = useState([]);
  const [hsDetailItems, setHsDetailItems] = useState([]);
  const [tempHsDetailItemsOndate, setTempHsDetailItemsOndate] = useState([]);
  const [hsDetailItemsOndate, setHsDetailItemsOndate] = useState([]);
  const [statisticsAreaHsItems, setStatisticsAreaHsItems] = useState([]);
  const [tempStatisticsHsItems, setTempStatisticsHsItems] = useState([]);
  const [statisticsHsItems, setStatisticsHsItems] =
    useState([]);

  // futureNuri
  const [cnt, setCnt] = useState<FutureNuriCnt>({
    login: null,
    unlogin: null,
    prd1OnlineCnt: null,
    prd1CompleteCnt: null,
    prd2OnlineCnt: null,
    prd2CompleteCnt: null,
    prd3OnlineCnt: null,
    prd3CompleteCnt: null,
    prd4OnlineCnt: null,
    prd4CompleteCnt: null,
  });
  const [cntHs, setCntHs] = useState<FutureNuriCnt>({
    login: null,
    unlogin: null,
    prd1OnlineCnt: null,
    prd1CompleteCnt: null,
    prd2OnlineCnt: null,
    prd2CompleteCnt: null,
    prd3OnlineCnt: null,
    prd3CompleteCnt: null,
    prd4OnlineCnt: null,
    prd4CompleteCnt: null,
  });

  // 표로 보기
  const [showAsTable, setShowAsTable] = useState(false);
  // 선택된 지역
  const [area, setArea] = useState('');
  // 선택된 중학교, 고등학교
  const [currentSchool, setCurrentSchool] = useState('고등학교');
  // 0: 실사, 1: 최적화, 2: 당일
  const [inspectLevel, setInspectLevel] = useState(0);
  // 0: 전체, 1: 완료, 2: 미완료
  const [completeLevel, setCompleteLevel] = useState(0);
  // 0: 전체, 1: 온라인, 2: 인트라넷, 3: 지필, 4: 완료
  const [processStatus, setProcessStatus] = useState(0);
  // 0: 전체, 1~: 교시
  const [period, setPeriod] = useState(0);
  // 사이드바 숨김여부
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);
  // 로그인 여부
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // 팝업창 오픈
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [popupTitle, setPopupTitle] = useState<string>('');
  const [popupContent, setPopupContent] = useState<string>('');

  useEffect(() => {
    const token = window.sessionStorage.getItem('kice_token');
    try {
      parseJwt(token!);
    } catch (e) {
      navigate('/');
      setIsLoggedIn(false);
    }
    if (!token || Object.keys(parseJwt(token)).length === 0) {
      navigate('/');
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  }, [
    window.sessionStorage.getItem('kice_token'),
    window.sessionStorage.getItem('kice_token_id')
  ]);

  useEffect(() => {
    initSocketConnection();

    return () => {
      disconnectSocket();
    };
  }, []);

  const [isDataAvailable, setIsDataAvailable] = useState<boolean>(false);

  useEffect(() => {
    if (!socket) return;

    socket.on('connect', () => {
      socket.on('check_data', (res) => {
        setIsDataAvailable(true);
        setTempDetailItemsOndate(res);
      });
      socket.on('check_data_detail', (res) => {
        setDetailItems(res);
      });
      socket.on('check_data_detail_onday', (res) => {
        setDetailItemsOndate(res);
      });
      socket.on('check_statistics_area', (data) => {
        setStatisticsAreaItems(data);
      });
      socket.on('check_statistics', (res) => {
        setTempStatisticsItems(res[0].data);
      });
      socket.on('check_data_highschool', (res) => {
        setTempHsDetailItemsOndate(res);
      });
      socket.on('check_data_detail_highschool', (res) => {
        setHsDetailItems(res);
      });
      socket.on('check_data_detail_onday_highschool', (res) => {
        setHsDetailItemsOndate(res);
      });
      socket.on('check_statistics_area_highschool', (data) => {
        setStatisticsAreaHsItems(data);
      });
      socket.on('check_statistics_highschool', (res) => {
        setTempStatisticsHsItems(res[0].data);
      });
      socket.on('future_nuri_cnt', (res) => {
        setCnt(res);
      });
      socket.on('future_nuri_cnt_highschool', (res) => {
        setCntHs(res);
      });
    });
    return () => {
      socket.disconnect();
    }
  }, [socket]);

  useEffect(() => {
    const accessToken = window.sessionStorage.getItem('kice_token');
    // @ts-ignore
    const area = Boolean(accessToken) && Boolean(parseJwt(accessToken).area)
      // @ts-ignore
      ? parseJwt(accessToken).area
      : null;
    if (Boolean(area)) {
      const filteredStatisticsItems: any = statisticsAreaItems.filter(({gubun}: {
        gubun: string
      }) => gubun === area)[0];
      const filteredStatisticsItemsHs: any = statisticsAreaHsItems.filter(({gubun}: {
        gubun: string
      }) => gubun === area)[0];
      setItems(tempDetailItemsOndate.filter(({gubun}: { gubun: string }) => gubun === area));
      setDetailItems(detailItems.filter(({gubun}: { gubun: string }) => gubun === area));
      setDetailItemsOndate(detailItemsOndate.filter(({gubun}: { gubun: string }) => gubun === area));
      setStatisticsItems(filteredStatisticsItems?.data);
      setHsItems(tempHsDetailItemsOndate.filter(({gubun}: { gubun: string }) => gubun === area));
      setHsDetailItems(hsDetailItems.filter(({gubun}: { gubun: string }) => gubun === area));
      setHsDetailItemsOndate(hsDetailItemsOndate.filter(({gubun}: { gubun: string }) => gubun === area));
      setStatisticsHsItems(filteredStatisticsItemsHs?.data);
    } else {
      setItems(tempDetailItemsOndate);
      setStatisticsItems(tempStatisticsItems);
      setHsItems(tempHsDetailItemsOndate);
      setStatisticsHsItems(tempStatisticsHsItems);
    }
  }, [
    window.sessionStorage.getItem('kice_token'),
    JSON.stringify(statisticsAreaItems),
    JSON.stringify(statisticsAreaHsItems),
    JSON.stringify(detailItems),
    JSON.stringify(detailItemsOndate),
    JSON.stringify(hsDetailItems),
    JSON.stringify(hsDetailItemsOndate),
    JSON.stringify(tempDetailItemsOndate),
    JSON.stringify(tempHsDetailItemsOndate),
    JSON.stringify(tempStatisticsItems),
    JSON.stringify(tempStatisticsHsItems),
  ])

  return (
    <Box display="flex" flexDirection="column">
      <Header
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        showAsTable={showAsTable}
        setShowAsTable={setShowAsTable}
      />
      <Box sx={{
        display: 'flex',
        width: '100%',
        ...!isLoggedIn && ({
          height: `calc(100vh - ${headerHeight}px)`,
          justifyContent: 'center',
          alignItems: 'center'
        }),
      }}>
        {isLoggedIn && (
          <SideBar
            isSidebarHidden={isSidebarHidden}
            setIsSidebarHidden={setIsSidebarHidden}
          />
        )}
        <Box
          sx={{
            width: isSidebarHidden ? '100%' : `calc(100% - ${sidebarWidth}px)`,
            transition: 'width 0.3s linear',
            justifyContent: 'center',
          }}
        >
          <Routes>
            <Route
              path="/"
              element={
                <LoginPage setIsLoggedIn={setIsLoggedIn}/>
              }
            />
            {isDataAvailable
              ? <>
                <Route
                  path="/home"
                  element={
                    <DashboardMain
                      records={(() => {
                        switch (currentSchool) {
                          case '중학교':
                            return items;
                          case '고등학교':
                            return hsItems;
                          default:
                            return items;
                        }
                      })()}
                      setArea={setArea}
                      currentSchool={currentSchool}
                      setCurrentSchool={setCurrentSchool}
                      inspectLevel={inspectLevel}
                      setInspectLevel={setInspectLevel}
                      setCompleteLevel={setCompleteLevel}
                      showAsTable={showAsTable}
                    />
                  }
                />
                {["/detail", '/detail_stat'].map((path) => (
                  <Route
                    path={path}
                    element={
                      <DashboardDetail
                        records={(() => {
                          switch (currentSchool) {
                            case '중학교':
                              return inspectLevel === 2 ? detailItemsOndate : detailItems;
                            case '고등학교':
                              return inspectLevel === 2 ? hsDetailItemsOndate : hsDetailItems;
                            default:
                              return detailItemsOndate;
                          }
                        })()}
                        area={area}
                        currentSchool={currentSchool}
                        inspectLevel={inspectLevel}
                        completeLevel={completeLevel}
                        processStatus={processStatus}
                        setProcessStatus={setProcessStatus}
                        period={period}
                        setPeriod={setPeriod}
                        setIsPopupOpen={setIsPopupOpen}
                        setPopupTitle={setPopupTitle}
                        setPopupContent={setPopupContent}
                        showAsTable={showAsTable}
                      />
                    }
                  />
                ))}
                <Route
                  path="/statistics"
                  element={
                    <Statistics
                      count={(() => {
                        switch (currentSchool) {
                          case '중학교':
                            return cnt;
                          case '고등학교':
                            return cntHs;
                          default:
                            return cnt;
                        }
                      })()}
                      currentSchool={currentSchool}
                      records={(() => {
                        switch (currentSchool) {
                          case '중학교':
                            return statisticsItems;
                          case '고등학교':
                            return statisticsHsItems;
                          default:
                            return statisticsItems;
                        }
                      })()}
                      setCurrentSchool={setCurrentSchool}
                      setArea={setArea}
                      setInspectLevel={setInspectLevel}
                      setProcessStatus={setProcessStatus}
                      setCompleteLevel={setCompleteLevel}
                      setPeriod={setPeriod}
                    />
                  }
                />
                {["/school_detail", "/school_detail_stat"].map((path) => (
                  <Route
                    path={path}
                    element={
                      <SchoolDetail
                        currentSchool={currentSchool}
                        inspectLevel={inspectLevel}
                      />
                    }
                  />
                ))}
              </>
              : <Route path='*' element={
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: colorPalette.background,
                    minHeight: '100%',
                    width: `calc(100vw - ${sidebarWidth})`,
                  }}
                >
                  <CircularProgress/>
                </Box>
              }/>
            }
          </Routes>
        </Box>
      </Box>
      {isPopupOpen && (
        <SimpleDialog open={isPopupOpen} title={popupTitle} content={popupContent} setOpen={setIsPopupOpen}/>
      )}
    </Box>
  );
}
