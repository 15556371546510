import { MenuItem } from '@mui/material';

export let socketUrl = `ws://localhost:8000`;
export let baseURL = `http://localhost:8001`;
export let futureNuriUrl = 'http://175.45.211.85:8082';
export let downloadURL = 'http://localhost';

if (process.env.NODE_ENV === 'production') {
  socketUrl = `ws://api-kice-ws-temp.k-one.co.kr`;
  baseURL = `http://api-kice-temp.k-one.co.kr`;
  futureNuriUrl = 'https://tnaea.kice.re.kr';
  downloadURL = 'http://kice.k-one.co.kr';
}

export const items = [
  <MenuItem value="중학교">국가수준 1차(중학교)</MenuItem>,
  <MenuItem value="고등학교">국가수준 2차(고등학교)</MenuItem>
];

export const searchItems = [
  <MenuItem value="학교명">학교명</MenuItem>,
  <MenuItem value="agent_name">현장요원</MenuItem>,
  <MenuItem value="team_code">조</MenuItem>,
];
