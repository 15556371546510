import {io} from 'socket.io-client';
import axios, {InternalAxiosRequestConfig} from 'axios';
import {baseURL, futureNuriUrl, socketUrl} from './consts';

export const client = axios.create({
  baseURL,
  timeout: 4000,
});

// @ts-ignore
client.interceptors.request.use((config): InternalAxiosRequestConfig<any> => {
  // 요청이 전달되기 전에 작업 수행
  const token = sessionStorage.getItem('kice_token');
  if (config.url !== '/login_dashboard' && config.url !== '/logout') {
    if (token) {
      config.headers['kice_token'] = token;
      return config;
    } else {
      Promise.reject('not a valid request.');
    }
  } else return config;
}, function (error) {
  // 요청 오류가 있는 작업 수행
  return Promise.reject(error);
});

export const socket = io(socketUrl, {
  forceNew: true,
  transports: ['websocket'],
});

export const futureNuriClient = axios.create({
  baseURL: futureNuriUrl,
  timeout: 4000
})

export const initSocketConnection = () => {
  if (!socket) return;
  if (socket && !socket.connected) {
    socket.connect();
  }
};

export const disconnectSocket = () => {
  if (!socket || !socket.connected) {
    return;
  }
  socket.disconnect();
};
