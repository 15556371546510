import React from 'react';

interface Props {
  fill: string;
}

function StatisticsIcon(props: Props) {
  const { fill } = props;
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_254_4979" maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36">
        <rect width="36" height="36" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_254_4979)">
        <path
          d="M7.00012 31.5C6.32512 31.5 5.73962 31.2521 5.24363 30.7564C4.74788 30.2604 4.5 29.6749 4.5 28.9999V7.00012C4.5 6.32512 4.74788 5.73963 5.24363 5.24363C5.73962 4.74788 6.32512 4.5 7.00012 4.5H28.9999C29.6749 4.5 30.2604 4.74788 30.7564 5.24363C31.2521 5.73963 31.5 6.32512 31.5 7.00012V28.9999C31.5 29.6749 31.2521 30.2604 30.7564 30.7564C30.2604 31.2521 29.6749 31.5 28.9999 31.5H7.00012ZM7.00012 25.2251V28.9999H28.9999V15.7249L20.6501 24.4249C20.4084 24.6834 20.1146 24.8146 19.7689 24.8186C19.4229 24.8229 19.1249 24.7 18.8749 24.45L13.3249 18.9L7.00012 25.2251ZM7.00012 21.675L12.45 16.2251C12.7 15.9751 12.9916 15.8501 13.3249 15.8501C13.6584 15.8501 13.9501 15.9751 14.2001 16.2251L19.725 21.75L28.9999 12.1001V7.00012H7.00012V21.675ZM7.00012 15.7249V12.1001V21.75V15.3499V25.2251V18.9V25.35V15.7249ZM7.00012 21.675V7.00012V21.75V15.3499V21.675ZM7.00012 25.2251V18.9V25.35V15.7249V28.9999V25.2251Z"
          fill={fill} />
      </g>
    </svg>

  );
}

export default StatisticsIcon;
