import { FormControl, Select, SelectChangeEvent, SxProps, Typography } from '@mui/material';
import { colorPalette, defaultFontFamily } from '../const';
import { inputFieldStyle } from '../definition/styles';
import { useEffect, useMemo, useState } from 'react';

/**
 * Author: 백도연
 * Created: 2024/01/29
 * description: 셀렉트박스
 *
 * props:
 * isMobile: 모바일인지 여부
 * onChange: 선택했을때 동작
 * currentConditionKey: 현재 선택된 key (선택된 키가 없으면 placeholder 띄움)
 * wrapperSx: formControl에 줄 스타일
 * defaultPlaceholder: 아무것도 없을 때 나타낼 플레이스홀더
 * fullWidth: width 적용할건지
 * etcSx: 기타 스타일링
 * children: 자식 컴포넌트
 */

interface SelectBoxProps {
  children: JSX.Element[];
  currentConditionKey: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  id?: string;
  inputMode?: boolean;
  isMobile?: boolean;
  underline?: boolean;
  wrapperSx?: SxProps;
  defaultPlaceholder?: string;
  fullWidth?: boolean;
  etcSx?: SxProps;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
}

const SelectBox = (props: SelectBoxProps) => {
  const {
    id = '',
    inputMode = false,
    isMobile,
    underline = true,
    currentConditionKey,
    onChange,
    wrapperSx,
    defaultPlaceholder,
    etcSx,
    fullWidth,
    children,
    error = false,
    helperText = '',
    disabled = false,
  } = props;

  const [focused, setFocused] = useState(false);
  const [errored, setErrored] = useState(error);

  useEffect(() => {
    setErrored(error);
  }, [error])

  useEffect(() => {
    if (focused) {
      setErrored(false);
    }
  }, [focused]);

  const style = useMemo(() => {
    if (inputMode) {
      return {
        ...inputFieldStyle,
        background: errored ? colorPalette.red50 : colorPalette.white,
        borderColor:
          errored
            ? colorPalette.red500
            : focused
              ? colorPalette.blue500
              : colorPalette.gray300,
        height: '100%',
        '& .MuiSelect-outlined': {
          p: 0
        },
        '&.MuiSvgIcon-root ': {
          fill: colorPalette.gray900
        },
        ...isMobile && ({
          '&.MuiInputBase-root ': {
            border: 'none'
          }
        }),
        '& .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
          borderColor:
            errored
              ? colorPalette.red500
              : focused
                ? colorPalette.blue500
                : isMobile
                  ? colorPalette.gray300
                  : 'transparent'
        },
        fontFamily: defaultFontFamily,
        color: colorPalette.gray900,
        fontSize: 14,
        fontWeight: 400
      }
    } else {
      return {
        height: 36,
        fontFamily: defaultFontFamily,
        color: colorPalette.gray900,
        fontSize: 14,
        fontWeight: 400
      }
    }
  }, [inputMode, errored, focused, isMobile]);

  return (
    <FormControl
      id={id}
      sx={{
      ...inputMode && ({
        height: 50,
        position: 'relative',
        cursor: 'pointer',
      }),
      ...wrapperSx
    }}>
      <Select
        disabled={disabled}
        error={errored}
        fullWidth={fullWidth}
        size="small"
        variant={inputMode && (!underline || isMobile) ? 'outlined' : 'standard'}
        onChange={(e) => {
          setFocused(false);
          onChange(e);
        }}
        onBlur={(e) => setFocused(false)}
        onFocus={() => {
          setFocused(true);
        }}
        displayEmpty={Boolean(defaultPlaceholder)}
        renderValue={currentConditionKey ? undefined : () => (
          <Typography sx={{
            color: colorPalette.gray900,
            fontFamily: defaultFontFamily,
            fontSize: 18,
            fontWeight: 400
          }}>
            {defaultPlaceholder}
          </Typography>
        )}
        value={currentConditionKey || ''}
        // @ts-ignore
        sx={{
          ...style,
          ...etcSx
        }}
        MenuProps={{
          MenuListProps: {
            style: {
              padding: 0
            }
          },
          PaperProps: {
            style: {
              marginTop: isMobile ? 4 : 0,
              maxHeight: 56 * (children && children.length > 0 ? 10 : 4), // Adjust this value to control the max height
              overflowY: 'auto'
            }
          }
        }}
      >
        {children}
      </Select>
      {(errored && inputMode) && (
        <Typography sx={{
          left: 0,
          fontSize: 12,
          color: colorPalette.red500,
          fontFamily: defaultFontFamily,
          position: 'absolute',
          bottom: -20
        }}>
          {helperText}
        </Typography>
      )}
    </FormControl>
  );
};

export default SelectBox;
