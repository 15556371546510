import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useInterval } from 'usehooks-ts';
import { client } from '../../network.config';
import { colorPalette, defaultFontFamily } from "../../const";
import { downloadURL } from "../../consts";
import { ReactComponent as BackButton } from '../../assets/back_btn.svg';

interface Props {
  currentSchool: string;
  inspectLevel: number;
}

interface Item {
  cl1_file1: string;
  cl1_file1_name: string;
  cl1_file2: string;
  cl1_file2_name: string;
  cl1_file3: string;
  cl1_file3_name: string;
  cl2_file1: string;
  cl2_file1_name: string;
  cl2_file2: string;
  cl2_file2_name: string;
  cl2_file3: string;
  cl2_file4_name: string;
  job02_client: string;
  job02_client_address: string;
  job02_client_tel: string;
  job02_client_manager_phone: string;
  job02_etc1: string;
  job02_etc2: string;
  early_yn: string;
  area: string;
  spy1class: string;
  spy1name: string;
  spy1tel: string;
  spy2class: string;
  spy2name: string;
  spy2tel: string;
  level1InspectResult: string;
  level1Inspect1FileName: string;
  level1InspectFile1: string;
  level1Inspect2FileName: string;
  level1InspectFile2: string;
  level1Inspect3FileName: string;
  level1InspectFile3: string;
  level1Inspect4FileName: string;
  level1InspectFile4: string;
  level1Inspect5FileName: string;
  level1InspectFile5: string;
  level1Inspect6FileName: string;
  level1InspectFile6: string;
  level1Inspect7FileName: string;
  level1InspectFile7: string;
  level1Inspect8FileName: string;
  level1InspectFile8: string;
  level1Inspect9FileName: string;
  level1InspectFile9: string;
  level2InspectResult: string;
  level2Inspect1FileName: string;
  level2InspectFile1: string;
  level2Inspect2FileName: string;
  level2InspectFile2: string;
  level2Inspect3FileName: string;
  level2InspectFile3: string;
  level2Inspect4FileName: string;
  level2InspectFile4: string;
  level2Inspect5FileName: string;
  level2InspectFile5: string;
  level2Inspect6FileName: string;
  level2InspectFile6: string;
  level2Inspect7FileName: string;
  level2InspectFile7: string;
  level2Inspect8FileName: string;
  level2InspectFile8: string;
  level2Inspect9FileName: string;
  level2InspectFile9: string;
  agent_name_1: string;
  agent_tel_1: string;
  agent_name_2: string;
  agent_tel_2: string;
}

// school detail
function StatisticsDetail(props: Props) {
  const navigate = useNavigate();
  const { currentSchool, inspectLevel } = props;
  const location = useLocation();
  const [item, setItem] = useState<Item>({
    cl1_file1: '',
    cl1_file1_name: '',
    cl1_file2: '',
    cl1_file2_name: '',
    cl1_file3: '',
    cl1_file3_name: '',
    cl2_file1: '',
    cl2_file1_name: '',
    cl2_file2: '',
    cl2_file2_name: '',
    cl2_file3: '',
    cl2_file4_name: '',
    job02_client: '',
    job02_client_address: '',
    job02_client_tel: '',
    job02_client_manager_phone: '',
    job02_etc1: '',
    job02_etc2: '',
    early_yn: '',
    area: '',
    spy1class: '',
    spy1name: '',
    spy1tel: '',
    spy2class: '',
    spy2name: '',
    spy2tel: '',
    level1InspectResult: '',
    level1Inspect1FileName: '',
    level1InspectFile1: '',
    level1Inspect2FileName: '',
    level1InspectFile2: '',
    level1Inspect3FileName: '',
    level1InspectFile3: '',
    level1Inspect4FileName: '',
    level1InspectFile4: '',
    level1Inspect5FileName: '',
    level1InspectFile5: '',
    level1Inspect6FileName: '',
    level1InspectFile6: '',
    level1Inspect7FileName: '',
    level1InspectFile7: '',
    level1Inspect8FileName: '',
    level1InspectFile8: '',
    level1Inspect9FileName: '',
    level1InspectFile9: '',
    level2InspectResult: '',
    level2Inspect1FileName: '',
    level2InspectFile1: '',
    level2Inspect2FileName: '',
    level2InspectFile2: '',
    level2Inspect3FileName: '',
    level2InspectFile3: '',
    level2Inspect4FileName: '',
    level2InspectFile4: '',
    level2Inspect5FileName: '',
    level2InspectFile5: '',
    level2Inspect6FileName: '',
    level2InspectFile6: '',
    level2Inspect7FileName: '',
    level2InspectFile7: '',
    level2Inspect8FileName: '',
    level2InspectFile8: '',
    level2Inspect9FileName: '',
    level2InspectFile9: '',
    agent_name_1: '',
    agent_tel_1: '',
    agent_name_2: '',
    agent_tel_2: '',
  });

  const headerCellStyle = {
    color: colorPalette.gray700,
    lineHeight: 1.5,
    background: colorPalette.indigo50,
    fontFamily: defaultFontFamily,
    fontSize: 24,
    fontWeight: 700,
    textAlign: 'center',
  }

  const contentCellStyle = {
    p: '16px !important',
    color: colorPalette.gray700,
    fontFamily: defaultFontFamily,
    fontSize: 24,
    fontWeight: 400,
  }

  const { schoolData } = location.state;
  const pk = schoolData.job02_pk;

  const [classList, setClassList] = useState<any[]>([]);
  const getData = useCallback(async (pk: string) => {
    const res1 = await client
      .get(
        `/get_school_detail_upper?pk=${pk}`
      )
      .catch((e) => {
        console.error(e);
      });
    const res2 = await client
      .get(`/get_school_detail_middle?pk=${pk}`)
      .catch((e) => {
        console.error(e);
      });

    return [res1, res2];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getClassInfoList = useCallback(async (pk: string) => {
    const res = await client
      .get(`/get_school_detail_lower?pk=${pk}`)
      .catch((e) => {
        console.error(e);
      });

    return [res];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getData(pk).then((res) => {
      const d = Object.assign({}, ...res.map((r: any) => r.data.data));
      setItem(d);
    });
    getClassInfoList(pk).then((res) => {
      const d = Object.assign([], ...res.map((r: any) => r.data.data));
      setClassList(d);
    });
  }, [location, getData, getClassInfoList]);

  useInterval(() => {
    getData(pk).then((res) => {
      const d = Object.assign([], ...res.map((r: any) => r.data.data));
      setItem(d);
    });
    getClassInfoList(pk).then((res) => {
      const d = Object.assign([], ...res.map((r: any) => r.data.data));
      setClassList(d);
    });
  }, 5000);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        background: colorPalette.background,
        px: 3,
        py: 1.25,
      }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}>
          <IconButton
            sx={{ p: 0 }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <BackButton />
          </IconButton>
          <Typography sx={{
            color: colorPalette.gray900,
            fontFamily: defaultFontFamily,
            fontSize: 32,
            fontWeight: 600,
            lineHeight: 1.25,
          }}>
            {schoolData['지역']}
          </Typography>
        </Box>
      </Box>
      <TableContainer>
        <Box sx={{
          p: 3
        }}>
          <Typography sx={{
            color: colorPalette.gray700,
            fontFamily: defaultFontFamily,
            fontSize: 24,
            fontWeight: 700,
          }}>
            학교 정보
          </Typography>
          <Table
            sx={{
              mt: 3,
              borderTop: `1px solid ${colorPalette.gray300}`,
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    py: '16px !important',
                    ...headerCellStyle,
                  }}
                >
                  학교명
                </TableCell>
                <TableCell
                  sx={contentCellStyle}
                >
                  {/*{item?.job02_client ?? ''}*/}
                  {/*{item.early_yn === 'Y'*/}
                  {/*  ? `(사전시험 대상학교 / ${item.job02_etc1})`*/}
                  {/*  : ''}*/}
                  {schoolData['학교명']}
                </TableCell>
                <TableCell
                  sx={{
                    py: '16px !important',
                    ...headerCellStyle,
                  }}
                >
                  학교 주소
                </TableCell>
                <TableCell
                  sx={contentCellStyle}
                >
                  {/*{item?.job02_client_address ?? ''}*/}
                  {schoolData['학교주소']}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    py: '16px !important',
                    ...headerCellStyle,
                  }}
                >
                  학교 연락처
                </TableCell>
                <TableCell
                  sx={contentCellStyle}
                >
                  {/*{item?.job02_client_tel ?? ''}*/}
                  {schoolData['학교연락처']}
                </TableCell>
                <TableCell
                  sx={{
                    py: '16px !important',
                    ...headerCellStyle,
                  }}
                >
                  교사 연락처
                </TableCell>
                <TableCell
                  sx={contentCellStyle}
                >
                  {/*{item?.job02_client_manager_phone ?? ''}*/}
                  {schoolData['학교선생님연락처']}
                </TableCell>
              </TableRow>
              {inspectLevel === 2 && (
                <>
                  <Typography sx={{
                    my: 3,
                    color: colorPalette.gray700,
                    fontFamily: defaultFontFamily,
                    fontSize: 24,
                    fontWeight: 700,
                  }}>
                    요원 정보
                  </Typography>
                  <TableRow sx={{
                    borderTop: `1px solid ${colorPalette.gray300}`,
                  }}>
                    <TableCell
                      sx={{
                        py: '16px !important',
                        ...headerCellStyle,
                      }}
                    >
                      {/*{item?.spy1class ?? ''} 현장요원*/}
                      요원 #1
                    </TableCell>
                    <TableCell
                      sx={contentCellStyle}
                    >
                      {
                        //@ts-ignore
                        item.agent_name_1
                      }
                    </TableCell>
                    <TableCell
                      sx={{
                        py: '16px !important',
                        ...headerCellStyle,
                      }}
                    >
                      {/*{item?.spy1class ?? ''} 현장요원 연락처*/}
                      요원 #1 연락처
                    </TableCell>
                    <TableCell
                      sx={contentCellStyle}
                    >
                      {
                        //@ts-ignore
                        item.agent_tel_1
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        py: '16px !important',
                        ...headerCellStyle,
                      }}
                    >
                      {/*{item?.spy2class ?? ''} 현장요원*/}
                      요원 #2
                    </TableCell>
                    <TableCell
                      sx={contentCellStyle}
                    >
                      {
                        //@ts-ignore
                        item.agent_name_2
                      }
                    </TableCell>
                    <TableCell
                      sx={{
                        py: '16px !important',
                        ...headerCellStyle,
                      }}
                    >
                      {/*{item?.spy2class ?? ''} 현장요원 연락처*/}
                      요원 #2 연락처
                    </TableCell>
                    <TableCell
                      sx={contentCellStyle}
                    >
                      {
                        //@ts-ignore
                        item.agent_tel_2
                      }
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
          {inspectLevel !== 2 && (
            <>
              <Typography sx={{
                my: 3,
                color: colorPalette.gray700,
                fontFamily: defaultFontFamily,
                fontSize: 24,
                fontWeight: 700,
              }}>
                점검 정보
              </Typography>
              <Box sx={{
                overflow: 'scroll'
              }}>
                <Table sx={{
                  borderTop: `1px solid ${colorPalette.gray300}`
                }}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          py: '16px !important',
                          ...headerCellStyle,
                          whiteSpace: 'nowrap',
                        }}
                      >
                        상세상태
                      </TableCell>
                      {new Array(9).fill('').map((_, index) => (
                        <TableCell
                          align="center"
                          sx={{
                            ...headerCellStyle,
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {`증빙자료 #${index + 1}`}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {inspectLevel === 0 && (
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            ...contentCellStyle,
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {item?.level1InspectResult ?? ''}
                        </TableCell>
                        {new Array(9).fill('').map((_, index) => (
                          <TableCell
                            align="center"
                            sx={{
                              ...contentCellStyle,
                              whiteSpace: 'nowrap',
                              cursor:
                                /*@ts-ignore*/
                                (item?.[`level1Inspect${index + 1}FileName`] ?? '') !== ''
                                  ? 'pointer'
                                  : 'default',
                            }}
                          >
                            <a
                              style={{ color: colorPalette.blue500, textDecoration: 'underline' }}
                              download
                              target="_blank" rel="noopener noreferrer"
                              /*@ts-ignore*/
                              href={`${downloadURL}${item?.[`level1InspectFile${index + 1}`]}`}
                            >
                              {/*@ts-ignore*/}
                              {item?.[`level1Inspect${index + 1}FileName`]}
                            </a>
                          </TableCell>
                        ))}
                      </TableRow>
                    )}
                    {inspectLevel === 1 && (
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            ...contentCellStyle,
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {item?.level2InspectResult ?? ''}
                        </TableCell>
                        {new Array(9).fill('').map((_, index) => (
                          <TableCell
                            align="center"
                            sx={{
                              ...contentCellStyle,
                              whiteSpace: 'nowrap',
                              cursor:
                                /*@ts-ignore*/
                                (item?.[`level2Inspect${index + 1}FileName`] ?? '') !== ''
                                  ? 'pointer'
                                  : 'default',
                            }}
                          >
                            <a
                              style={{ color: colorPalette.blue500, textDecoration: 'underline' }}
                              download
                              target="_blank" rel="noopener noreferrer"
                              /*@ts-ignore*/
                              href={`${downloadURL}${item?.[`level2InspectFile${index + 1}`]}`}
                            >
                              {/*@ts-ignore*/}
                              {item?.[`level2Inspect${index + 1}FileName`]}
                            </a>
                          </TableCell>
                        ))}
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </>
          )}
          {inspectLevel === 2 && (
            <>
              <Typography sx={{
                my: 3,
                color: colorPalette.gray700,
                fontFamily: defaultFontFamily,
                fontSize: 24,
                fontWeight: 700,
              }}>
                시험 당일 점검 파일
              </Typography>
              <Table sx={{ color: colorPalette.gray700, borderTop: `1px solid ${colorPalette.gray300}` }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        py: '16px !important',
                        ...headerCellStyle,
                      }}
                    >
                      반
                    </TableCell>
                    {new Array(3).fill('').map((_, index) => (
                      <TableCell
                        align="center"
                        sx={{
                          py: '16px !important',
                          ...headerCellStyle,
                        }}
                      >
                        {`#${index + 1}`}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={contentCellStyle}
                    >
                      {classList[0]?.class_name ?? ''}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={contentCellStyle}
                    >
                      <a
                        style={{color: colorPalette.blue500, textDecoration: 'underline'}}
                        download
                        target="_blank" rel="noopener noreferrer"
                        /*@ts-ignore*/
                        href={`${downloadURL}${item?.cl1_file1}`}
                      >
                        {/*@ts-ignore*/}
                        {item?.cl1_file1_name}
                      </a>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={contentCellStyle}
                    >
                      <a
                        style={{color: colorPalette.blue500, textDecoration: 'underline'}}
                        download
                        target="_blank" rel="noopener noreferrer"
                        /*@ts-ignore*/
                        href={`${downloadURL}${item?.cl1_file2}`}
                      >
                        {/*@ts-ignore*/}
                        {item?.cl1_file2_name}
                      </a>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={contentCellStyle}
                    >
                      <a
                        style={{color: colorPalette.blue500, textDecoration: 'underline'}}
                        download
                        target="_blank" rel="noopener noreferrer"
                        /*@ts-ignore*/
                        href={`${downloadURL}${item?.cl1_file3}`}
                      >
                        {/*@ts-ignore*/}
                        {item?.cl1_file3_name}
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={contentCellStyle}
                    >
                      {classList[1]?.class_name ?? ''}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={contentCellStyle}
                    >
                      <a
                        style={{color: colorPalette.blue500, textDecoration: 'underline'}}
                        download
                        target="_blank" rel="noopener noreferrer"
                        /*@ts-ignore*/
                        href={`${downloadURL}${item?.cl2_file1}`}
                      >
                        {/*@ts-ignore*/}
                        {item?.cl2_file1_name}
                      </a>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={contentCellStyle}
                    >
                      <a
                        style={{color: colorPalette.blue500, textDecoration: 'underline'}}
                        download
                        target="_blank" rel="noopener noreferrer"
                        /*@ts-ignore*/
                        href={`${downloadURL}${item?.cl2_file2}`}
                      >
                        {/*@ts-ignore*/}
                        {item?.cl2_file2_name}
                      </a>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={contentCellStyle}
                    >
                      <a
                        style={{color: colorPalette.blue500, textDecoration: 'underline'}}
                        download
                        target="_blank" rel="noopener noreferrer"
                        /*@ts-ignore*/
                        href={`${downloadURL}${item?.cl2_file3}`}
                      >
                        {/*@ts-ignore*/}
                        {item?.cl2_file3_name}
                      </a>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Typography sx={{
                my: 3,
                color: colorPalette.gray700,
                fontFamily: defaultFontFamily,
                fontSize: 24,
                fontWeight: 700,
              }}>
                시험 진행 상태
              </Typography>
              <Table sx={{ color: colorPalette.gray700, borderTop: `1px solid ${colorPalette.gray300}` }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        py: '16px !important',
                        ...headerCellStyle,
                      }}
                    >
                      반
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        py: '16px !important',
                        ...headerCellStyle,
                      }}
                    >
                      당일점검
                    </TableCell>
                    {new Array(3).fill('').map((_, index) => (
                      <TableCell
                        align="center"
                        sx={{
                          py: '16px !important',
                          ...headerCellStyle,
                        }}
                      >
                        {`${index + 1}교시`}
                      </TableCell>
                    ))}
                    {currentSchool === '중학교' ? (
                      <TableCell
                        align="center"
                        sx={{
                          py: '16px !important',
                          ...headerCellStyle,
                        }}
                      >
                        4교시
                      </TableCell>
                    ) : (
                      // eslint-disable-next-line react/jsx-no-useless-fragment
                      <></>
                    )}
                    <TableCell
                      align="center"
                      sx={{
                        py: '16px !important',
                        ...headerCellStyle
                      }}
                    >
                      마무리
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {classList.length > 0 ? (
                    classList.map((period, index) => (
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={contentCellStyle}
                        >
                          {period.class_name}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={contentCellStyle}
                        >
                          {index === 0 ? period.col0_1 : period.col0_2}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={contentCellStyle}
                        >
                          {period.col1}<br/>{period.col1 !== '대기' ? `(${period.st1})` : ''}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={contentCellStyle}
                        >
                          {period.col2}<br/>{period.col2 !== '대기' ? `(${period.st2})` : ''}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={contentCellStyle}
                        >
                          {period.col3}<br/>{period.col3 !== '대기' ? `(${period.st3})` : ''}
                        </TableCell>
                        {currentSchool === '중학교' ? (
                          <TableCell
                            align="center"
                            sx={contentCellStyle}
                          >
                            {period.col4}<br/>{period.col4 !== '대기' ? `(${period.st4})` : ''}
                          </TableCell>
                        ) : (
                          // eslint-disable-next-line react/jsx-no-useless-fragment
                          <></>
                        )}
                        <TableCell
                          align="center"
                          sx={contentCellStyle}
                        >
                          {period.col5}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <></>
                  )}
                </TableBody>
              </Table>
            </>
          )}
        </Box>
      </TableContainer>
    </Box>
  );
}

export default StatisticsDetail;
