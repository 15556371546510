import {
  Box,
  Typography,
  IconButton, Table, TableContainer, TableRow, TableCell, TableHead,
} from '@mui/material';
import {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {items} from '../../consts';
import {ReactComponent as ArrowLeft} from '../../assets/arrow_left.svg';
import {ReactComponent as ArrowRight} from '../../assets/arrow_right.svg';
import {colorPalette, defaultFontFamily, sidebarWidth} from "../../const";
import SelectBox from "../../components/SelectBox";

interface Props {
  records: any[];
  setArea: (area: string) => void;
  currentSchool: string;
  setCurrentSchool: (currentSchool: string) => void;
  inspectLevel: number;
  setInspectLevel: Dispatch<SetStateAction<number>>;
  setCompleteLevel: Dispatch<SetStateAction<number>>;
  showAsTable: boolean;
}

function DashboardMain(props: Props) {
  const navigate = useNavigate();
  const {
    records,
    setArea,
    currentSchool,
    setCurrentSchool,
    inspectLevel,
    setInspectLevel,
    setCompleteLevel,
    showAsTable
  } = props;
  const inspectTypes = ['실사', '최적화', '시험 당일'];
  const onTestTypes = ['시험 당일 점검', '시험 진행', '시험 후 정리'];
  const [onTestStates, setOnTestStates] = useState<number[]>([]);

  useEffect(() => {
    setOnTestStates(new Array(records.length).fill(0));
  }, [records.length])
  const textStyle = {
    color: colorPalette.gray900,
    fontFamily: defaultFontFamily,
    fontWeight: 600
  };

  const getColor = (percentage: number) => {
    if (percentage > 0 && percentage < 50) {
      return colorPalette.red500;
    }
    if (percentage >= 50 && percentage < 100) {
      return colorPalette.yellow500;
    } else if (percentage === 100) {
      return colorPalette.kice_green;
    }
    return colorPalette.gray500;
  }

  const getGrids = useCallback(() => (
    records.map((item, schoolIndex) => (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 1.5,
          background: colorPalette.white,
          border: `1px solid ${colorPalette.gray200}`,
          minWidth: 254,
          p: 1.5,
        }}
      >
        {[0, 1].includes(inspectLevel) && (
          <>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: 48,
              mb: 1.25,
            }}>
              <Typography sx={{
                ...textStyle,
                fontSize: 40,
              }}>
                {item.gubun}
              </Typography>
              <Typography
                sx={{
                  ...textStyle,
                  fontSize: 36,
                  ...inspectLevel === 0 && ({
                    color: getColor((item.pre.cmpl / item.pre.total) * 100),
                  }),
                  ...inspectLevel === 1 && ({
                    color: getColor((item.optimized.cmpl / item.optimized.total) * 100),
                  }),
                }}
              >
                {inspectLevel === 0 && `${((item.pre.cmpl / item.pre.total) * 100).toFixed(
                  0
                )}%`}
                {inspectLevel === 1 && `${((item.optimized.cmpl / item.optimized.total) * 100).toFixed(
                  0
                )}%`}
              </Typography>
            </Box>
            <Typography sx={{
              ...textStyle,
              fontSize: 18,
              height: 40,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              {inspectTypes[inspectLevel]}
            </Typography>
            <Box
              onClick={() => {
                setArea(item.gubun);
                setCompleteLevel(99);
                navigate('/detail');
              }}
              sx={{
                m: '10px 4px 0',
                display: 'flex',
                height: 75,
                justifyContent: 'center',
                alignItems: 'center',
                background: colorPalette.gray100,
                borderRadius: 1,
                cursor: 'pointer',
              }}>
              <Typography sx={{
                ...textStyle,
                fontSize: 28,
                width: '50%',
                textAlign: 'center',
              }}>
                전체
              </Typography>
              <Box sx={{
                ...textStyle,
                fontSize: 28,
                width: '50%',
                textAlign: 'center',
              }}>
                {inspectLevel === 0 && item.pre.total}
                {inspectLevel === 1 && item.optimized.total}
              </Box>
            </Box>
            <Box sx={{
              m: 0.5,
              height: 75,
              gap: 0.5,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <Box
                sx={{
                  background: colorPalette.gray100,
                  borderRadius: 1,
                  height: '100%',
                  flex: 1,
                  p: '6px 12px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setArea(item.gubun);
                  setCompleteLevel(1);
                  navigate('/detail');
                }}
              >
                <Typography sx={{
                  ...textStyle,
                  color: colorPalette.kice_green,
                  fontSize: 14,
                }}>
                  완료
                </Typography>
                <Typography sx={{
                  width: '100%',
                  textAlign: 'right',
                  ...textStyle,
                  color: colorPalette.gray700,
                  fontSize: 36,
                }}>
                  {inspectLevel === 0 && item.pre.cmpl}
                  {inspectLevel === 1 && item.optimized.cmpl}
                </Typography>
              </Box>
              <Box
                sx={{
                  background: colorPalette.gray100,
                  borderRadius: 1,
                  height: '100%',
                  flex: 1,
                  p: '6px 12px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setArea(item.gubun);
                  setCompleteLevel(2);
                  navigate('/detail');
                }}
              >
                <Typography sx={{
                  ...textStyle,
                  color: colorPalette.orange500,
                  fontSize: 14,
                }}>
                  미완료
                </Typography>
                <Typography sx={{
                  width: '100%',
                  textAlign: 'right',
                  ...textStyle,
                  color: colorPalette.gray700,
                  fontSize: 36,
                }}>
                  {inspectLevel === 0 && item.pre.incmpl}
                  {inspectLevel === 1 && item.optimized.incmpl}
                </Typography>
              </Box>
            </Box>
          </>
        )}
        {/*시험 당일*/}
        {inspectLevel === 2 && (
          <>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: 48,
              mb: 1.25,
            }}>
              <Typography sx={{
                ...textStyle,
                fontSize: 40,
              }}>
                {item.gubun}
              </Typography>
              <Typography
                sx={{
                  ...textStyle,
                  fontSize: 36,
                  color: getColor((() => {
                    switch (onTestStates[schoolIndex]) {
                      case 0:
                        return (item.ontest.inspect.cmpl / item.ontest.total) * 100;
                      case 2:
                        return (item.ontest.after.cmpl / item.ontest.total) * 100;
                      default:
                        return 0;
                    }
                  })()),
                }}
              >
                {(() => {
                  switch (onTestStates[schoolIndex]) {
                    case 0:
                      return `${(
                        (item.ontest.inspect.cmpl / item.ontest.total) *
                        100
                      ).toFixed(0)}%`;
                    case 2:
                      return `${(
                        (item.ontest.after.cmpl / item.ontest.total) *
                        100
                      ).toFixed(0)}%`;
                    default:
                      return '';
                  }
                })()}
              </Typography>
            </Box>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <IconButton
                sx={{p: 0}}
                onClick={(e) => {
                  setOnTestStates([
                    ...onTestStates.slice(0, schoolIndex),
                    onTestStates[schoolIndex] - 1 < 0 ? 2 : onTestStates[schoolIndex] - 1,
                    ...onTestStates.slice(schoolIndex + 1),
                  ])
                }}>
                <ArrowLeft/>
              </IconButton>
              <Typography sx={{
                ...textStyle,
                fontSize: 18,
                height: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                {onTestTypes[onTestStates[schoolIndex]]}
              </Typography>
              <IconButton
                sx={{p: 0}}
                onClick={(e) => {
                  setOnTestStates([
                    ...onTestStates.slice(0, schoolIndex),
                    onTestStates[schoolIndex] + 1 > 2 ? 0 : onTestStates[schoolIndex] + 1,
                    ...onTestStates.slice(schoolIndex + 1),
                  ])
                }}>
                <ArrowRight/>
              </IconButton>
            </Box>
            {onTestStates[schoolIndex] === 0 && (
              <>
                <Box sx={{
                  m: '10px 4px 0',
                  display: 'flex',
                  height: 75,
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: colorPalette.gray100,
                  borderRadius: 1,
                  cursor: 'pointer'
                }}
                     onClick={() => {
                       setArea(item.gubun);
                       setCompleteLevel(99);
                       navigate('/detail');
                     }}>
                  <Typography sx={{
                    ...textStyle,
                    fontSize: 28,
                    width: '50%',
                    textAlign: 'center',
                  }}>
                    전체
                  </Typography>
                  <Box sx={{
                    ...textStyle,
                    fontSize: 28,
                    width: '50%',
                    textAlign: 'center',
                  }}>
                    {item.ontest.total}
                  </Box>
                </Box>
                <Box sx={{
                  m: 0.5,
                  height: 75,
                  gap: 0.5,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                  <Box
                    sx={{
                      background: colorPalette.gray100,
                      borderRadius: 1,
                      height: '100%',
                      flex: 1,
                      p: '6px 12px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      setArea(item.gubun);
                      setCompleteLevel(1);
                      navigate('/detail');
                    }}
                  >
                    <Typography sx={{
                      ...textStyle,
                      color: colorPalette.kice_green,
                      fontSize: 14,
                    }}>
                      완료
                    </Typography>
                    <Typography sx={{
                      width: '100%',
                      textAlign: 'right',
                      ...textStyle,
                      color: colorPalette.gray700,
                      fontSize: 36,
                    }}>
                      {item.ontest.inspect.cmpl}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      background: colorPalette.gray100,
                      borderRadius: 1,
                      height: '100%',
                      flex: 1,
                      p: '6px 12px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      setArea(item.gubun);
                      setCompleteLevel(2);
                      navigate('/detail');
                    }}
                  >
                    <Typography sx={{
                      ...textStyle,
                      color: colorPalette.orange500,
                      fontSize: 14,
                    }}>
                      미완료
                    </Typography>
                    <Typography sx={{
                      width: '100%',
                      textAlign: 'right',
                      ...textStyle,
                      color: colorPalette.gray700,
                      fontSize: 36,
                    }}>
                      {item.ontest.inspect.incmpl}
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
            {onTestStates[schoolIndex] === 1 && (
              <>
                <Box sx={{
                  mt: 1.25,
                  mx: 0.5,
                  height: 75,
                  gap: 0.5,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                  <Box
                    sx={{
                      background: colorPalette.gray100,
                      borderRadius: 1,
                      height: '100%',
                      flex: 1,
                      p: '6px 12px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      cursor: 'pointer'
                    }}
                    onClick={(e) => {
                      setArea(item.gubun);
                      setCompleteLevel(5);
                      navigate('/detail');
                    }}
                  >
                    <Typography sx={{
                      ...textStyle,
                      fontSize: 14,
                    }}>
                      1교시
                    </Typography>
                    <Typography sx={{
                      width: '100%',
                      textAlign: 'right',
                      ...textStyle,
                      color: getColor((item.ontest.proc.first / item.ontest.total) * 100),
                      fontSize: 36,
                    }}>
                      {Boolean(item.ontest.proc.first)
                        ? item.ontest.proc.first
                        : '-'}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      background: colorPalette.gray100,
                      borderRadius: 1,
                      height: '100%',
                      flex: 1,
                      p: '6px 12px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      setArea(item.gubun);
                      setCompleteLevel(6);
                      navigate('/detail');
                    }}
                  >
                    <Typography sx={{
                      ...textStyle,
                      fontSize: 14,
                    }}>
                      2교시
                    </Typography>
                    <Typography sx={{
                      width: '100%',
                      textAlign: 'right',
                      ...textStyle,
                      color: getColor((item.ontest.proc.second / item.ontest.total) * 100),
                      fontSize: 36,
                    }}>
                      {Boolean(item.ontest.proc.second)
                        ? item.ontest.proc.second
                        : '-'}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{
                  m: 0.5,
                  mb: 0,
                  height: 75,
                  gap: 0.5,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                     onClick={(e) => {
                       setArea(item.gubun);
                       setCompleteLevel(7);
                       navigate('/detail');
                     }}>
                  <Box
                    sx={{
                      background: colorPalette.gray100,
                      borderRadius: 1,
                      height: '100%',
                      flex: 1,
                      p: '6px 12px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography sx={{
                      ...textStyle,
                      fontSize: 14,
                    }}>
                      3교시
                    </Typography>
                    <Typography sx={{
                      width: '100%',
                      textAlign: 'right',
                      ...textStyle,
                      color: getColor((item.ontest.proc.third / item.ontest.total) * 100),
                      fontSize: 36,
                    }}>
                      {Boolean(item.ontest.proc.third)
                        ? item.ontest.proc.third
                        : '-'}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      background: colorPalette.gray100,
                      borderRadius: 1,
                      height: '100%',
                      flex: 1,
                      p: '6px 12px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      setArea(item.gubun);
                      setCompleteLevel(8);
                      navigate('/detail');
                    }}
                  >
                    <Typography sx={{
                      ...textStyle,
                      fontSize: 14,
                    }}>
                      4교시
                    </Typography>
                    <Typography sx={{
                      width: '100%',
                      textAlign: 'right',
                      ...textStyle,
                      color: getColor((item.ontest.proc.fourth / item.ontest.total) * 100),
                      fontSize: 36,
                    }}>
                      {currentSchool === '중학교' && Boolean(item.ontest.proc.fourth)
                        ? item.ontest.proc.fourth
                        : '-'}
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
            {onTestStates[schoolIndex] === 2 && (
              <>
                <Box sx={{
                  m: '10px 4px 0',
                  display: 'flex',
                  height: 75,
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: colorPalette.gray100,
                  borderRadius: 1,
                  cursor: 'pointer'
                }}
                     onClick={() => {
                       setArea(item.gubun);
                       setCompleteLevel(0);
                       navigate('/detail');
                     }}
                >
                  <Typography sx={{
                    ...textStyle,
                    fontSize: 28,
                    width: '50%',
                    textAlign: 'center',
                  }}>
                    전체
                  </Typography>
                  <Box sx={{
                    ...textStyle,
                    fontSize: 28,
                    width: '50%',
                    textAlign: 'center',
                  }}>
                    {item.ontest.total}
                  </Box>
                </Box>
                <Box sx={{
                  m: 0.5,
                  height: 75,
                  gap: 0.5,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                  <Box
                    sx={{
                      background: colorPalette.gray100,
                      borderRadius: 1,
                      height: '100%',
                      flex: 1,
                      p: '6px 12px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      setArea(item.gubun);
                      setCompleteLevel(3);
                      navigate('/detail');
                    }}
                  >
                    <Typography sx={{
                      ...textStyle,
                      color: colorPalette.kice_green,
                      fontSize: 14,
                    }}>
                      완료
                    </Typography>
                    <Typography sx={{
                      width: '100%',
                      textAlign: 'right',
                      ...textStyle,
                      color: colorPalette.gray700,
                      fontSize: 36,
                    }}>
                      {item.ontest.after.cmpl}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      background: colorPalette.gray100,
                      borderRadius: 1,
                      height: '100%',
                      flex: 1,
                      p: '6px 12px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      setArea(item.gubun);
                      setCompleteLevel(4);
                      navigate('/detail');
                    }}
                  >
                    <Typography sx={{
                      ...textStyle,
                      color: colorPalette.orange500,
                      fontSize: 14,
                    }}>
                      미완료
                    </Typography>
                    <Typography sx={{
                      width: '100%',
                      textAlign: 'right',
                      ...textStyle,
                      color: colorPalette.gray700,
                      fontSize: 36,
                    }}>
                      {item.ontest.after.incmpl}
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 2.5,
              alignItems: 'center'
            }}>
              {/*<Typography*/}
              {/*  sx={{*/}
              {/*    ...textStyle,*/}
              {/*    fontSize: 18,*/}
              {/*    height: 40,*/}
              {/*    gap: 0.5,*/}
              {/*    display: 'flex',*/}
              {/*    alignItems: 'center',*/}
              {/*    justifyContent: 'center',*/}
              {/*  }}*/}
              {/*>*/}
              {/*      <span style={{*/}
              {/*        fontSize: 24,*/}
              {/*        lineHeight: 1.5,*/}
              {/*      }}>*/}
              {/*        {`${(*/}
              {/*          (item.ontest.agent_status.departure_cnt / item.ontest.total) **/}
              {/*          100*/}
              {/*        ).toFixed(0)}`}*/}
              {/*      </span>*/}
              {/*  %*/}
              {/*</Typography>*/}
              <Typography
                sx={{
                  ...textStyle,
                  fontSize: 18,
                  height: 40,
                  gap: 0.5,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setArea(item.gubun);
                  setCompleteLevel(0);
                  navigate('/detail', {
                    state: {
                      showAgentStatus: true,
                      showArrivalStatus: true,
                    }
                  });
                }}
              >
                미도착
                <span style={{
                  fontSize: 24,
                  lineHeight: 1.5,
                }}>
                      {item.ontest.agent_status.unarrival_cnt}
                    </span>
              </Typography>
              <Typography
                sx={{
                  ...textStyle,
                  fontSize: 18,
                  height: 40,
                  gap: 0.5,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setArea(item.gubun);
                  setCompleteLevel(0);
                  navigate('/detail', {
                    state: {
                      showAgentStatus: true,
                      showArrivalStatus: false,
                    }
                  });
                }}
              >
                미출발
                <span style={{
                  fontSize: 24,
                  lineHeight: 1.5,
                }}>
                      {item.ontest.agent_status.undeparture_cnt}
                    </span>
              </Typography>
            </Box>
          </>
        )}
      </Box>
    ))
  ), [currentSchool, inspectLevel, inspectTypes, navigate, onTestStates, onTestTypes, records, setArea, setCompleteLevel, textStyle])

  const defaultFont = {
    color: colorPalette.gray900,
    fontFamily: defaultFontFamily,
    fontSize: 18,
  };

  const getTableRows = useCallback(() => (
    records.map((item, schoolIndex) => (
      <TableRow
        sx={{
          background: colorPalette.white,
        }}
      >
        {/*시험 당일*/}
        {inspectLevel === 2 && (
          <>
            <TableCell
              align="center"
              sx={{
                p: 0.75,
                ...defaultFont,
                fontSize: 18,
              }}
            >
              {item.gubun}
            </TableCell>
            {/*<TableCell*/}
            {/*  align="center"*/}
            {/*  sx={{*/}
            {/*    p: 0.75,*/}
            {/*    ...defaultFont,*/}
            {/*    fontSize: 18,*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {`${(*/}
            {/*    (item.ontest.agent_status.departure_cnt / item.ontest.total) **/}
            {/*    100*/}
            {/*  ).toFixed(0)}%`}*/}
            {/*</TableCell>*/}
            <TableCell
              align="center"
              sx={{
                p: 0.75,
                ...defaultFont,
                fontSize: 18,
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: colorPalette.gray300
                }
              }}
              onClick={(e) => {
                setArea(item.gubun);
                setCompleteLevel(0);
                navigate('/detail', {
                  state: {
                    showAgentStatus: true,
                    showArrivalStatus: true,
                  }
                });
              }}
            >
              {item.ontest.agent_status.unarrival_cnt}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                p: 0.75,
                ...defaultFont,
                fontSize: 18,
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: colorPalette.gray300
                }
              }}
              onClick={(e) => {
                setArea(item.gubun);
                setCompleteLevel(0);
                navigate('/detail', {
                  state: {
                    showAgentStatus: true,
                    showArrivalStatus: false,
                  }
                });
              }}
            >
              {item.ontest.agent_status.undeparture_cnt}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                p: 0.75,
                ...defaultFont,
                fontSize: 18,
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: colorPalette.gray300
                }
              }}
              onClick={(e) => {
                setArea(item.gubun);
                setCompleteLevel(99);
                navigate('/detail');
              }}
            >
              {item.ontest.total}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                p: 0.75,
                ...defaultFont,
                fontSize: 18,
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: colorPalette.gray300
                }
              }}
              onClick={(e) => {
                setArea(item.gubun);
                setCompleteLevel(1);
                navigate('/detail');
              }}
            >
              {item.ontest.inspect.cmpl}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                p: 0.75,
                ...defaultFont,
                fontSize: 18,
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: colorPalette.gray300
                }
              }}
              onClick={(e) => {
                setArea(item.gubun);
                setCompleteLevel(2);
                navigate('/detail');
              }}
            >
              {item.ontest.inspect.incmpl}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                p: 0.75,
                ...defaultFont,
                fontSize: 18,
                color: getColor((item.ontest.proc.first / item.ontest.total) * 100),
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: colorPalette.gray300
                }
              }}
              onClick={(e) => {
                setArea(item.gubun);
                setCompleteLevel(5);
                navigate('/detail');
              }}
            >
              {Boolean(item.ontest.proc.first)
                ? item.ontest.proc.first
                : '-'}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                p: 0.75,
                ...defaultFont,
                fontSize: 18,
                color: getColor((item.ontest.proc.second / item.ontest.total) * 100),
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: colorPalette.gray300
                }
              }}
              onClick={(e) => {
                setArea(item.gubun);
                setCompleteLevel(6);
                navigate('/detail');
              }}
            >
              {Boolean(item.ontest.proc.second)
                ? item.ontest.proc.second
                : '-'}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                p: 0.75,
                ...defaultFont,
                fontSize: 18,
                color: getColor((item.ontest.proc.third / item.ontest.total) * 100),
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: colorPalette.gray300
                }
              }}
              onClick={(e) => {
                setArea(item.gubun);
                setCompleteLevel(7);
                navigate('/detail');
              }}
            >
              {Boolean(item.ontest.proc.third)
                ? item.ontest.proc.third
                : '-'}
            </TableCell>
            {currentSchool === '중학교' && (
              <TableCell
                align="center"
                sx={{
                  p: 0.75,
                  ...defaultFont,
                  fontSize: 18,
                  color: getColor((item.ontest.proc.fourth / item.ontest.total) * 100),
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: colorPalette.gray300
                  }
                }}
                onClick={(e) => {
                  setArea(item.gubun);
                  setCompleteLevel(8);
                  navigate('/detail');
                }}
              >
                {Boolean(item.ontest.proc.fourth)
                  ? item.ontest.proc.fourth
                  : '-'}
              </TableCell>
            )}
            <TableCell
              align="center"
              sx={{
                p: 0.75,
                ...defaultFont,
                fontSize: 18,
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: colorPalette.gray300
                }
              }}
              onClick={(e) => {
                setArea(item.gubun);
                setCompleteLevel(99);
                navigate('/detail');
              }}
            >
              {item.ontest.total}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                p: 0.75,
                ...defaultFont,
                fontSize: 18,
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: colorPalette.gray300
                }
              }}
              onClick={(e) => {
                setArea(item.gubun);
                setCompleteLevel(3);
                navigate('/detail');
              }}
            >
              {item.ontest.after.cmpl}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                p: 0.75,
                ...defaultFont,
                fontSize: 18,
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: colorPalette.gray300
                }
              }}
              onClick={(e) => {
                setArea(item.gubun);
                setCompleteLevel(4);
                navigate('/detail');
              }}
            >
              {item.ontest.after.incmpl}
            </TableCell>
          </>
        )}
      </TableRow>
    ))
  ), [currentSchool, defaultFont, inspectLevel, inspectTypes, navigate, records, setArea, setCompleteLevel, textStyle])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        background: colorPalette.background,
        width: `calc(100vw - ${sidebarWidth})`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          backgroundColor: colorPalette.background,
          borderBottom: `1px solid ${colorPalette.gray200}`
        }}
      >
        <Box>
          <Box display="flex" alignItems='center' height={60} width="100%" sx={{
            p: '8px 24px',
            gap: 1,
            flex: 1,
          }}>
            <Typography
              sx={{
                fontFamily: defaultFontFamily,
                fontSize: 24,
                fontWeight: 600,
                color: colorPalette.gray900
              }}
            >
              차수
            </Typography>
            <Box
              sx={{
                display: 'flex',
                width: 352,
              }}
            >
              <SelectBox
                fullWidth
                underline={false}
                wrapperSx={{
                  width: 352
                }}
                etcSx={{
                  color: colorPalette.gray900,
                  fontFamily: defaultFontFamily,
                  fontSize: 18,
                  fontWeight: 400
                }}
                inputMode
                currentConditionKey={currentSchool}
                onChange={(e) => setCurrentSchool(e.target.value)}
                children={items}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
        }}>
          <Box sx={{
            height: 40,
            p: 0.5,
            borderRadius: '9px',
            background: colorPalette.gray200,
            gap: 0.5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            {inspectTypes.map((inspectType, typeIndex) => (
              <Typography
                onClick={(e) => setInspectLevel(typeIndex)}
                sx={{
                  cursor: 'pointer',
                  flex: 1,
                  minWidth: 80,
                  textAlign: 'center',
                  color: colorPalette.gray900,
                  fontFamily: defaultFontFamily,
                  fontSize: 18,
                  fontWeight: 600,
                  ...typeIndex === inspectLevel && ({
                    borderRadius: 1,
                    background: colorPalette.white,
                    boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 1px 0px rgba(0, 0, 0, 0.04)'
                  })
                }}>
                {inspectType}
              </Typography>
            ))}
          </Box>
        </Box>
        <Box sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          mr: 3,
          gap: 2.5
        }}>
          {[{
            title: '우수',
            color: colorPalette.kice_green
          }, {
            title: '무난',
            color: colorPalette.yellow500
          }, {
            title: '저조',
            color: colorPalette.red500
          }].map(({title, color}, index, array) => (
            <Box sx={{
              display: 'flex',
            }}>
              <Box sx={{
                width: 20, height: 20,
                borderRadius: '50%',
                backgroundColor: color,
                mr: 1,
              }}/>
              <Typography sx={{
                color: colorPalette.gray900,
                fontFamily: defaultFontFamily,
                fontSize: 18,
                fontWeight: 700,
                ...index < array.length - 1 && ({
                  mr: 2.5
                })
              }}>
                {title}
              </Typography>
              {index < array.length - 1 && (
                <Box sx={{width: 2, height: 20, backgroundColor: colorPalette.gray300}}/>
              )}
            </Box>
          ))}
        </Box>
      </Box>
      {(([0, 1].includes(inspectLevel)) || (inspectLevel === 2 && !showAsTable)) && (
        <Box sx={{
          backgroundColor: colorPalette.background,
          p: 3,
          display: 'flex',
          gap: 2,
          flexWrap: 'wrap'
        }}>
          {getGrids()}
        </Box>
      )}
      {!([0, 1].includes(inspectLevel)) && showAsTable && (
        <TableContainer sx={{
          p: 3,
        }}>
          <Table sx={{
            backgroundColor: colorPalette.background,
          }}>
            <TableHead>
              <TableRow>
                <TableCell
                  align="center" rowSpan={3} sx={{
                    p: 0.5,
                  ...defaultFont,
                  fontSize: 18
                }}>
                  지역구분
                </TableCell>
                {/*<TableCell*/}
                {/*  align="center" rowSpan={3} sx={{*/}
                {/*    p: 0.5,*/}
                {/*  ...defaultFont,*/}
                {/*  fontSize: 18*/}
                {/*}}>*/}
                {/*  도착률*/}
                {/*</TableCell>*/}
                <TableCell
                  align="center" rowSpan={3} sx={{
                    p: 0.5,
                  ...defaultFont,
                  fontSize: 18
                }}>
                  미도착
                </TableCell>
                <TableCell
                  align="center" rowSpan={3} sx={{
                    p: 0.5,
                  ...defaultFont,
                  fontSize: 18
                }}>
                  미출발
                </TableCell>
                <TableCell colSpan={9} align="center" sx={{
                  p: 0.5,
                  ...defaultFont,
                  fontSize: 18
                }}>
                  진행구분
                </TableCell>
              </TableRow>
              <TableRow>
                {onTestTypes.map((type, index) => (
                  <TableCell colSpan={index === 1 && currentSchool === '중학교' ? 4 : 3} align="center" sx={{
                    p: 0.5,
                    ...defaultFont,
                    fontSize: 18
                  }}>{type}</TableCell>))}
              </TableRow>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    p: 0.5,
                    ...defaultFont,
                    fontSize: 18
                  }}>전체</TableCell>
                <TableCell
                  align="center"
                  sx={{
                    p: 0.5,
                    ...defaultFont,
                    fontSize: 18
                  }}>완료</TableCell>
                <TableCell
                  align="center"
                  sx={{
                    p: 0.5,
                    ...defaultFont,
                    fontSize: 18
                  }}>미완료</TableCell>
                <TableCell
                  align="center"
                  sx={{
                    p: 0.5,
                    ...defaultFont,
                    fontSize: 18
                  }}>1교시</TableCell>
                <TableCell
                  align="center"
                  sx={{
                    p: 0.5,
                    ...defaultFont,
                    fontSize: 18
                  }}>2교시</TableCell>
                <TableCell
                  align="center"
                  sx={{
                    p: 0.5,
                    ...defaultFont,
                    fontSize: 18
                  }}>3교시</TableCell>
                {currentSchool === '중학교' && (
                  <TableCell
                    align="center"
                    sx={{
                      p: 0.5,
                      ...defaultFont,
                      fontSize: 18
                    }}>
                    4교시
                  </TableCell>
                )}
                <TableCell
                  align="center"
                  sx={{
                    p: 0.5,
                    ...defaultFont,
                    fontSize: 18
                  }}>전체</TableCell>
                <TableCell
                  align="center"
                  sx={{
                    p: 0.5,
                    ...defaultFont,
                    fontSize: 18
                  }}>완료</TableCell>
                <TableCell
                  align="center"
                  sx={{
                    p: 0.5,
                    ...defaultFont,
                    fontSize: 18
                  }}>미완료</TableCell>
              </TableRow>
            </TableHead>
            {getTableRows()}
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}

export default DashboardMain;
