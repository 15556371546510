import { InputBaseProps, TextField, SxProps } from '@mui/material';
import { ChangeEventHandler, KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import { colorPalette, defaultFontFamily } from '../const';
import OpenInNew from '../assets/open_in_new.svg';
import WarningSvg from '../assets/warning.svg';
import {inputFieldStyle} from "../definition/styles";

interface InputFieldProps {
  focusable?: boolean;
  multiline?: boolean;
  readonly?: boolean;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  height?: number;
  name?: string;
  id?: string;
  type?: string;
  value: unknown;
  pointer?: boolean;
  onClick?: any;
  icon?: string | undefined;
  check?: boolean;
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  pattern?: string;
  maxLength?: number;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFocus?: InputBaseProps['onFocus'];
  onBlur?: InputBaseProps['onBlur'];
  sx?: SxProps;
  endAdornment?: JSX.Element;
  helperTextWidth?: string | number;
}

const InputField = (props: InputFieldProps) => {
  const {
    placeholder = '',
    helperText = '',
    error = false,
    multiline = false,
    readonly = false,
    height = 16,
    focusable = true,
    onChange = (e) => {
    },
    onClick = (e: any) => {
    },
    onFocus = (e) => {
    },
    onBlur = (e) => {
    },
    onKeyDown = (e) => {
    },
    name = '',
    id = '',
    type = 'text',
    value,
    sx,
    endAdornment = null,
    maxLength = 9999,
    pattern = null,
    check = false,
    pointer = false,
    icon,
    helperTextWidth = '100%',
  } = props;

  useEffect(() => {
    // @ts-ignore
    inputRef.current.setAttribute('style', `color: ${readonly ? colorPalette.gray500 : colorPalette.gray900};font-family: ${defaultFontFamily};font-size: 14px;font-weight: 400;cursor: ${pointer ? 'pointer' : 'text'};`);
  }, [readonly, pointer]);

  const [focused, setFocused] = useState(false);
  const inputRef = useRef(null);
  const [errored, setErrored] = useState(error);

  useEffect(() => {
    setErrored(error);
  }, [error]);

  // @ts-ignore
  return (
    <>
      <TextField
        inputRef={inputRef}
        // maxRows={100} // console error
        multiline={multiline}
        disabled={readonly}
        placeholder={placeholder}
        helperText={errored ? helperText : ''}
        error={errored}
        name={name}
        rows={multiline ? 5 : 1}
        variant="standard"
        id={id}
        type={type}
        value={value}
        onChange={onChange}
        onBlur={(e) => {
          setFocused(false);
          onBlur(e);
        }}
        onFocus={(e) => {
          if (focusable) {
            setErrored(false);
            setFocused(true);
            onFocus(e);
          } else {
            e.target.blur();
          }
        }}
        onKeyDown={onKeyDown}
        onClick={onClick}
        InputLabelProps={{
          shrink: true
        }}
        sx={{
          ...inputFieldStyle,
          // @ts-ignore
          background:
            readonly
              ? colorPalette.gray200
              : (errored || check)
                ? colorPalette.red50
                : colorPalette.white,
          // @ts-ignore
          position: 'relative',
          // @ts-ignore
          border:
            (errored || check)
              ? `1px solid ${colorPalette.red500}`
              : focused
                ? `1px solid ${colorPalette.blue500}`
                : `1px solid ${colorPalette.gray300}`,
          '& .MuiInputBase-root': {
            height
          },
          '& textarea': {
            height: `${height - 16}px !important`
          },
          '& .MuiInput-input.Mui-disabled': {
            color: 'red !important'
          },
          '& .MuiFormHelperText-root': {
            left: 0,
            fontSize: 12,
            fontFamily: defaultFontFamily,
            position: 'absolute',
            bottom: '-24px',
            width: helperTextWidth
          },
          // @ts-ignore
          cursor: pointer ? 'pointer' : 'default',
          ...sx
        }}
        InputProps={{
          disableUnderline: true,
          endAdornment:
            errored
              ? <WarningSvg />
              : endAdornment
                ? endAdornment
                : icon
                  ?  icon === 'openInNew' ?
                    <OpenInNew />
                    : icon
                  : <div />
        }}
        inputProps={{
          style: {
            padding: 0,
            fontSize: 14,
            cursor: props.pointer ? 'pointer' : 'default',
          },
          maxLength: maxLength,
          pattern: pattern
        }}
      />
    </>
  );
};

export default InputField;
