import { Box, ListItem, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {useCallback, useMemo, useState} from 'react';
import { ReactComponent as Arrow } from '../assets/arrow.svg';
import GridIcon from '../assets/grid.svg';
import { ReactComponent as LeftArrow } from '../assets/left.arrow.svg';
import { ReactComponent as RightArrow } from '../assets/right.arrow.svg';
import StatisticsIcon from '../assets/statistics.svg';
import {colorPalette, defaultFontFamily, sidebarWidth} from "../const";

interface Props {
  isSidebarHidden: boolean;
  setIsSidebarHidden: (hidden: boolean) => void;
}

function Sidebar(props: Props) {
  const navigate = useNavigate();
  const { isSidebarHidden, setIsSidebarHidden } = props;
  const sidebarItems = [
    {
      icon: (
        <Box className="wrapper" sx={{ width: '20px' }}>
          <GridIcon fill={colorPalette.indigo900} />
        </Box>
      ),
      text: '대시보드',
      goTo: '/home'
    },
    {
      icon: (
        <Box className="wrapper" sx={{ width: '20px' }}>
          <StatisticsIcon fill={colorPalette.indigo900} />
        </Box>
      ),
      text: '상황판',
      goTo: '/statistics'
    },
  ];

  const getOs = () => {
    const os = ['Windows', 'Linux', 'Mac']; // add your OS values
    return os.find((v) => navigator.userAgent.indexOf(v) >= 0);
  };

  const isPath = (index: number) => {
    const href = window.location.href.split('/');
    const locations = [
      ['home', 'detail', 'school_detail'],
      ['statistics', 'detail_stat', 'school_detail_stat'],
    ]
    return locations[index].includes(href[href.length - 1]);
  };

  return (
    <Box display="flex" flexDirection="row" alignItems="center" height="calc(100vh - 70px)">
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          width: (() => {
            if (!isSidebarHidden) return sidebarWidth;
            return getOs() === 'Mac' ? 0 : 10;
          })(),
          flexDirection: 'column',
          alignItems: 'flex-start',
          background: colorPalette.white,
          borderRight: `1px solid ${colorPalette.gray200}`,
          transition: 'width 0.3s linear',
        }}
      >
        {sidebarItems.map((item, idx) => (
          <ListItem
            sx={{
              display: 'flex',
              width: isSidebarHidden ? 0 : sidebarWidth,
              height: 52,
              padding: isSidebarHidden ? 0 : 0.75,
              alignItems: 'center',
              justifyContent: 'space-between',
              cursor: 'pointer',
              transition: 'all 0.3s linear',
            }}
            onClick={() => {
              navigate(item.goTo);
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              sx={{
                opacity: isSidebarHidden ? 0 : 1,
                transition: 'all 0.3s linear',
                height: '100%',
                width: '100%',
                p: 1,
                borderRadius: 1,
                background: !isPath(idx) ? 'transparent' : colorPalette.indigo50,
              }}
            >
              {item.icon}
              <Typography
                sx={{
                  fontFamily: defaultFontFamily,
                  fontSize: isSidebarHidden ? 0 : 24,
                  fontWeight: 700,
                  lineHeight: '28px',
                  ml: 1,
                  color: colorPalette.indigo900,
                  transition: 'font-size 0.3s linear',
                }}
              >
                {item.text}
              </Typography>
            </Box>
          </ListItem>
        ))}
      </Box>
      <Box
        sx={{
          cursor: 'pointer',
          width: '10px',
          height: '128px',
          borderRadius: '0px 2px 2px 0px',
          background: colorPalette.indigo900,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 999,
          position: 'fixed', // This makes the button fixed on scrolling
          top: '50%', // Adjust the vertical position as needed
          transform: 'translateY(-50%)', // Centers the button vertically
          left: isSidebarHidden ? 0 : sidebarWidth, // Adjust the horizontal position as needed
          transition: 'left 0.3s linear', // Adds a smooth transition
        }}
        onClick={() => {
          setIsSidebarHidden(!isSidebarHidden);
        }}
      >
        {isSidebarHidden ? <RightArrow /> : <LeftArrow />}
      </Box>
    </Box>
  );
}

export default Sidebar;
