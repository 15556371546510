import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton, CircularProgress,
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {items} from '../../consts';
import {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import {client} from '../../network.config';
import {ReactComponent as RefreshIcon} from '../../assets/refreshIcon.svg';
import {useInterval} from 'usehooks-ts';
import {colorPalette, defaultFontFamily} from "../../const";
import SelectBox from "../../components/SelectBox";
import {FutureNuriCnt} from "../../App";

interface Props {
  count: FutureNuriCnt;
  records: any[];
  currentSchool: string;
  setCurrentSchool: (currentSchool: string) => void;
  setArea: (area: string) => void;
  setInspectLevel: Dispatch<SetStateAction<number>>;
  setCompleteLevel: Dispatch<SetStateAction<number>>;
  setProcessStatus: Dispatch<SetStateAction<number>>;
  setPeriod: Dispatch<SetStateAction<number>>;
}

export default (props: Props) => {
  const {count, records, currentSchool, setCurrentSchool, setArea, setInspectLevel, setProcessStatus, setPeriod, setCompleteLevel} = props;

  const [filteredRecords, setFilteredRecords] = useState<any>([]);
  useEffect(() => {
    const recordCopy = JSON.parse(JSON.stringify(records));
    if (currentSchool === '중학교') {
      recordCopy.splice(4, 1);
      // recordCopy.push({
      //   "period": 5,
      //   "subject_code": null,
      //   "subject_name": "계",
      //   "b": records.reduce((pv, {b}) => pv + b, 0),
      //   "c1": records.reduce((pv, {c1}) => pv + c1, 0),
      //   "c2": records.reduce((pv, {c2}) => pv + c2, 0),
      //   "c3": records.reduce((pv, {c3}) => pv + c3, 0),
      //   "d": records.reduce((pv, {d}) => pv + d, 0),
      // })
    } else if (currentSchool === '고등학교') {
      recordCopy.splice(3, 2);
      // recordCopy.push({
      //   "period": 4,
      //   "subject_code": null,
      //   "subject_name": "계",
      //   "b": records.reduce((pv, {b}) => pv + b, 0),
      //   "c1": records.reduce((pv, {c1}) => pv + c1, 0),
      //   "c2": records.reduce((pv, {c2}) => pv + c2, 0),
      //   "c3": records.reduce((pv, {c3}) => pv + c3, 0),
      //   "d": records.reduce((pv, {d}) => pv + d, 0),
      // })
    }
    setFilteredRecords(recordCopy);
  }, [records, currentSchool]);

  const navigate = useNavigate();
  const tCellStyle = {
    py: 1,
    lineHeight: 1.5,
    color: colorPalette.gray700,
    textAlign: 'center',
    fontFamily: defaultFontFamily,
    fontSize: 40,
    fontWeight: 600,
    borderRight: `1px solid ${colorPalette.gray300}`,
  };

  function isNullOrUndefined(value?: number) {
    return value === null || typeof value === 'undefined';
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        width="100%"
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          backgroundColor: colorPalette.background,
          borderBottom: `1px solid ${colorPalette.gray200}`
        }}
      >
        <Box display="flex" alignItems='center' height={60} width="100%" sx={{
          p: '8px 24px',
          gap: 1,
        }}>
          <Typography
            sx={{
              fontFamily: defaultFontFamily,
              fontSize: 24,
              fontWeight: 600,
              color: colorPalette.gray900
            }}
          >
            차수
          </Typography>
          <Box
            sx={{
              display: 'flex',
              width: 352,
            }}
          >
            <SelectBox
              fullWidth
              underline={false}
              wrapperSx={{
                width: 352
              }}
              etcSx={{
                color: colorPalette.gray900,
                fontFamily: defaultFontFamily,
                fontSize: 18,
                fontWeight: 400
              }}
              inputMode
              currentConditionKey={currentSchool}
              onChange={(e) => setCurrentSchool(e.target.value)}
              children={items}
            />
          </Box>
          {/*<IconButton*/}
          {/*  onClick={async (e) => {*/}
          {/*    navigate(0);*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <RefreshIcon/>*/}
          {/*</IconButton>*/}
        </Box>
      </Box>
      <TableContainer sx={{
        p: 3
      }}>
        <Table sx={{tableLayout: 'fixed'}}>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  ...tCellStyle,
                  backgroundColor: colorPalette.indigo50,
                }}
                align="center"
                rowSpan={2}
              >
                항목
              </TableCell>
              <TableCell
                sx={{
                  ...tCellStyle,
                  backgroundColor: colorPalette.indigo50,
                  fontSize: 24,
                }}
                align="center"
                colSpan={3}
              >
                진행구분
              </TableCell>
              <TableCell
                sx={{
                  ...tCellStyle,
                  backgroundColor: colorPalette.indigo900,
                  color: colorPalette.white
                }}
                align="center"
                rowSpan={2}
              >
                완료
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  ...tCellStyle,
                  fontSize: 24,
                }}
                align="center"
              >
                온라인
              </TableCell>
              <TableCell
                sx={{
                  ...tCellStyle,
                  fontSize: 24,
                }}
                align="center"
              >
                인트라넷
              </TableCell>
              <TableCell
                sx={{
                  ...tCellStyle,
                  fontSize: 24,
                }}
                align="center"
              >
                지필
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/*<TableRow>*/}
            {/*  <TableCell*/}
            {/*    sx={{*/}
            {/*      ...tCellStyle,*/}
            {/*      // cursor: 'pointer',*/}
            {/*      py: '24px !important',*/}
            {/*      lineHeight: 1.2,*/}
            {/*      backgroundColor: colorPalette.indigo50*/}
            {/*    }}*/}
            {/*    align="center"*/}
            {/*    onClick={(e) => {*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    준비중*/}
            {/*  </TableCell>*/}
            {/*  <TableCell*/}
            {/*    colSpan={4}*/}
            {/*    sx={{*/}
            {/*      ...tCellStyle,*/}
            {/*      border: 'none',*/}
            {/*      borderBottom: `1px solid ${colorPalette.gray300}`,*/}
            {/*    }}*/}
            {/*    align="center"*/}
            {/*  >*/}
            {/*    {(Boolean(count.login) && Boolean(count.unlogin))*/}
            {/*      ? `로그인: ${count.login} | 미로그인: ${count.unlogin}`*/}
            {/*      : <CircularProgress />}*/}
            {/*  </TableCell>*/}
            {/*</TableRow>*/}
            {filteredRecords.map((item: any, index: number) => (
              <TableRow sx={{mt: 1}}>
                <TableCell
                  sx={{
                    ...tCellStyle,
                    cursor: 'pointer',
                    py: '24px !important',
                    lineHeight: 1.2,
                    backgroundColor: colorPalette.indigo50
                  }}
                  align="center"
                  onClick={(e) => {
                    setArea('전체');
                    setCompleteLevel(99);
                    setPeriod(index + 1);
                    setProcessStatus(0);
                    navigate('/detail_stat', {
                      state: {
                        showAgentStatus: false,
                        showArrivalStatus: false,
                      }
                    });
                  }}
                >
                  {Boolean(item.subject_name) ? item.subject_name : '계'}
                </TableCell>
                <TableCell
                  sx={{
                    ...tCellStyle,
                    cursor: 'pointer',
                    border: 'none',
                    borderBottom: `1px solid ${colorPalette.gray300}`
                  }}
                  align="center"
                  onClick={(e) => {
                    setArea('전체');
                    setCompleteLevel(99);
                    setPeriod(index + 1);
                    setProcessStatus(1);
                    setInspectLevel(2);
                    navigate('/detail_stat', {
                      state: {
                        showAgentStatus: false,
                        showArrivalStatus: false,
                      }
                    });
                  }}
                >
                  {(() => {
                    const cnt = count[`prd${index + 1}OnlineCnt` as keyof FutureNuriCnt] as number;
                    return !isNullOrUndefined(cnt)
                  })()
                    ? Number(count[`prd${index + 1}OnlineCnt` as keyof FutureNuriCnt]) - (item.c2 + item.c3) + Number(count[`prd${index + 1}UnloginCnt` as keyof FutureNuriCnt])
                  : <CircularProgress />}
                </TableCell>
                <TableCell
                  sx={{
                    ...tCellStyle,
                    cursor: 'pointer',
                    border: 'none',
                    borderBottom: `1px solid ${colorPalette.gray300}`
                  }}
                  align="center"
                  onClick={(e) => {
                    setArea('전체');
                    setCompleteLevel(99);
                    setPeriod(index + 1);
                    setProcessStatus(2);
                    setInspectLevel(2);
                    navigate('/detail_stat', {
                      state: {
                        showAgentStatus: false,
                        showArrivalStatus: false,
                      }
                    });
                  }}
                >
                  {item.c2}
                </TableCell>
                <TableCell
                  sx={{
                    ...tCellStyle,
                    cursor: 'pointer',
                    border: 'none',
                    borderBottom: `1px solid ${colorPalette.gray300}`
                  }}
                  align="center"
                  onClick={(e) => {
                    setArea('전체');
                    setCompleteLevel(99);
                    setPeriod(index + 1);
                    setProcessStatus(3);
                    setInspectLevel(2);
                    navigate('/detail_stat', {
                      state: {
                        showAgentStatus: false,
                        showArrivalStatus: false,
                      }
                    });
                  }}
                >
                  {item.c3}
                </TableCell>
                <TableCell
                  sx={{
                    ...tCellStyle,
                    cursor: 'pointer',
                    border: 'none',
                    borderBottom: `1px solid ${colorPalette.gray300}`
                  }}
                  align="center"
                  onClick={(e) => {
                    setArea('전체');
                    setCompleteLevel(99);
                    setPeriod(index + 1);
                    setProcessStatus(4);
                    setInspectLevel(2);
                    navigate('/detail_stat', {
                      state: {
                        showAgentStatus: false,
                        showArrivalStatus: false,
                      }
                    });
                  }}
                >
                  {(() => {
                    const cnt = count[`prd${index + 1}CompleteCnt` as keyof FutureNuriCnt] as number;
                    return !isNullOrUndefined(cnt)
                  })()
                      ? `${item.c2 + item.c3 + count[`prd${index + 1}CompleteCnt` as keyof FutureNuriCnt]}`
                      : <CircularProgress />}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
