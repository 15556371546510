import {Box, Button, Switch, Typography} from '@mui/material';
import {ReactComponent as KsystemLogo} from '../assets/ksyslogo.svg';
import {ReactComponent as KiceLogo} from '../assets/kice_logo.svg';
import {ReactComponent as LogoutIcon} from '../assets/logoutIcon.svg';
import {ReactComponent as UserIcon} from '../assets/userIcon.svg';
import {useNavigate} from 'react-router-dom';
import {colorPalette, defaultFontFamily, headerHeight} from "../const";
import {useEffect, useState} from "react";
import {parseJwt} from "../util";
import {client} from "../network.config";

interface LoginProps {
  isLoggedIn: boolean;
  setIsLoggedIn: (login: boolean) => void;
  showAsTable: boolean;
  setShowAsTable: (login: boolean) => void;
}

function Header(props: LoginProps) {
  const {isLoggedIn, setIsLoggedIn, showAsTable, setShowAsTable} = props;
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  useEffect(() => {
    const accessToken = window.sessionStorage.getItem('kice_token');
    if (accessToken) {
      setUserName(parseJwt(accessToken).memberName);
    }
  }, [window.sessionStorage.getItem('kice_token')]);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: headerHeight,
        width: "100%",
        backgroundColor: colorPalette.indigo900,
        px: '40px',
      }}
    >
      <Box display="flex" flexDirection="row" alignItems="center" gap={2.5}>
        <KiceLogo/>
        <Box sx={{
          width: 2,
          height: 20,
          backgroundColor: colorPalette.white,
        }}/>
        <KsystemLogo/>
        <Typography
          sx={{
            color: colorPalette.white,
            fontFamily: defaultFontFamily,
            fontSize: 20,
            fontWeight: 600,
          }}
        >
          학업성취도평가지원 시스템
        </Typography>
      </Box>
      {isLoggedIn && (
        <Box display="flex" alignItems="center" gap={1}>
          <Box display="flex" alignItems="center" sx={{
            color: colorPalette.white
          }}>
            <Typography sx={{
              fontFamily: defaultFontFamily,
              fontSize: 14,
              fontWeight: 600,
            }}>
              표로 보기
            </Typography>
            <Switch defaultChecked={false} checked={showAsTable} onChange={(e) => {
              setShowAsTable(e.target.checked);
            }}/>
          </Box>
          <Box display="flex" alignItems="center">
            <UserIcon/>
            <Typography
              sx={{
                ml: 0.5,
                color: colorPalette.white,
                fontFamily: defaultFontFamily,
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              {userName}
            </Typography>
          </Box>
          <Button
            onClick={async (e) => {
              navigate('/');
              const id = window.sessionStorage.getItem('kice_token_id');
              await client.post('/logout', {id} as any);
              window.sessionStorage.removeItem('kice_token');
              window.sessionStorage.removeItem('kice_token_id');
              setIsLoggedIn(false);

            }}
            sx={{
              display: 'flex',
              padding: '6px 12px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 0.5,
              background: colorPalette.kice_green,
            }}
          >
            <Typography
              sx={{
                color: colorPalette.white,
                fontFamily: defaultFontFamily,
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              로그아웃
            </Typography>
            <LogoutIcon/>
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default Header;
