import {Box, Button, Typography} from '@mui/material';
import {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {client} from '../network.config';
import {colorPalette, defaultFontFamily} from "../const";
import InputField from "../components/InputField";

interface LoginProps {
  setIsLoggedIn: (login: boolean) => void;
}

function LoginPage(props: LoginProps) {
  const {setIsLoggedIn} = props;
  const btnRef = useRef(null);
  const [id, setId] = useState('');
  const [passwd, setPasswd] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    // 로그인창으로 왔는데 토큰하고 아이디가 살아있을경우 강제삭제
    const token = window.sessionStorage.getItem('kice_token');
    const id = window.sessionStorage.getItem('kice_token_id');
    if (token && id) {
      window.sessionStorage.removeItem('kice_token');
      window.sessionStorage.removeItem('kice_token_id');
    }
  }, [
    window.sessionStorage.getItem('kice_token'),
    window.sessionStorage.getItem('kice_token_id')
  ])
  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Box
        sx={{
          maxWidth: 1000,
        }}
      >
        <Typography
          sx={{
            color: colorPalette.indigo900,
            fontFamily: defaultFontFamily,
            fontSize: 38,
            fontWeight: 600,
            lineHeight: 1.4,
            width: '100%',
            textAlign: 'center'
          }}
        >
          학업성취도 평가시스템 대시보드
        </Typography>
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              color: colorPalette.indigo900,
              fontFamily: defaultFontFamily,
              fontSize: 20,
              fontWeight: 600,
              lineHeight: 1.4,
              width: '100%',
              textAlign: 'left'
            }}
          >
            관리자 로그인
          </Typography>
          <InputField
            value={id}
            onChange={(e) => {
              setId(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                // @ts-ignore
                btnRef?.current.click();
              }
            }}
          />
          <InputField
            type='password'
            value={passwd}
            onChange={(e) => {
              setPasswd(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                // @ts-ignore
                btnRef?.current.click();
              }
            }}
          />
          <Button
            ref={btnRef}
            onClick={async () => {
              const res = await client.post('/login_dashboard', {
                userId: id,
                password: passwd,
              } as any);
              if (res.data.success) {
                new Promise((resolve, reject) => {
                  try {
                    const token = res.data.accessToken;
                    window.sessionStorage.setItem('kice_token', token);
                    window.sessionStorage.setItem('kice_token_id', res.data.id);
                    resolve(token);
                  } catch (e) {
                    reject(e);
                  }
                }).then((token) => {
                  setIsLoggedIn(true);
                  navigate('/home');
                })
              }
            }}
            sx={{
              mt: 3,
              display: 'flex',
              width: '350px',
              py: 1,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px',
              background: colorPalette.indigo900,
              color: colorPalette.white,
              fontFamily: defaultFontFamily,
              fontSize: 36,
              fontWeight: 600,
            }}
          >
            로그인
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default LoginPage;
