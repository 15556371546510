import {SxProps} from "@mui/material";
import {colorPalette, defaultFontFamily} from "../const";

export const inputFieldStyle: SxProps = {
  borderRadius: 0.5,
  background: colorPalette.white,
  border: `1px solid ${colorPalette.gray300}`,
  width: '100%',
  p: '16px 20px',
  color: colorPalette.gray900,
  fontFamily: defaultFontFamily,
  fontSize: 16,
  fontWeight: 600,
  '& > div': {
    height: '16px'
  }
};